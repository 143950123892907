import type { IconType } from './types';

export const TribuApasionadoSmile = ({
  className,
  width = 64,
  height = 64,
  color = '#F38D30',
}: IconType) => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 400 400"
  >
    <g>
      <circle fill={color} cx="200" cy="200" r="168.7" />
      <path d="M200,374.16c-96.03,0-174.15-78.13-174.15-174.16S103.97,25.84,200,25.84s174.16,78.13,174.16,174.15-78.13,174.16-174.16,174.16ZM200,36.75c-90.01,0-163.24,73.23-163.24,163.24s73.23,163.25,163.24,163.25,163.25-73.23,163.25-163.25S290.01,36.75,200,36.75Z" />
    </g>
    <g>
      <g>
        <path d="M200,322.21c-67.39,0-122.21-54.82-122.21-122.21s54.82-122.21,122.21-122.21,122.21,54.82,122.21,122.21-54.82,122.21-122.21,122.21ZM200,82.45c-64.82,0-117.55,52.73-117.55,117.55s52.73,117.55,117.55,117.55,117.55-52.73,117.55-117.55-52.73-117.55-117.55-117.55Z" />
        <path d="M200,324.94c-68.89,0-124.94-56.05-124.94-124.94s56.05-124.94,124.94-124.94,124.94,56.05,124.94,124.94-56.05,124.94-124.94,124.94ZM200,85.18c-63.31,0-114.82,51.51-114.82,114.82s51.51,114.83,114.82,114.83,114.83-51.51,114.83-114.83-51.51-114.82-114.83-114.82Z" />
      </g>
      <g>
        <path d="M240.47,217.97c-.38-.1-.72-.31-.98-.6-1.32-1.37-32.39-34.28-23.93-63.87,3.48-12.23,16.21-19.32,28.44-15.85,7.1,2.02,12.81,7.33,15.33,14.27,5.78-4.57,13.41-6.06,20.48-4.01,12.23,3.51,19.3,16.25,15.82,28.49-8.45,29.56-52.18,41.12-54.03,41.58-.37.11-.76.1-1.13,0ZM242.83,141.85c-9.92-2.82-20.26,2.92-23.1,12.84-7.03,24.57,17.07,53.19,22.01,58.74,7.13-2.1,42.69-13.68,49.71-38.23,2.83-9.93-2.92-20.27-12.84-23.12-6.78-1.97-14.08.1-18.82,5.32-.55.6-1.4.84-2.19.62h0c-.8-.22-1.4-.87-1.55-1.69-1.3-6.95-6.42-12.56-13.23-14.49Z" />
        <path d="M241.06,220.78c-.45,0-.9-.06-1.34-.19-.87-.24-1.65-.72-2.25-1.39-1.32-1.36-33.48-35.15-24.53-66.45,1.88-6.61,6.22-12.1,12.23-15.45,6.01-3.34,12.96-4.16,19.58-2.27,6.81,1.93,12.5,6.61,15.75,12.79,6-3.54,13.29-4.51,20.08-2.54,13.64,3.92,21.57,18.21,17.68,31.86-8.95,31.35-54.07,43-55.98,43.48-.38.11-.79.16-1.2.16ZM237.72,143.87c-6.95,0-13.36,4.58-15.36,11.58-6.19,21.66,13.79,47.28,20.28,54.86,9.51-3,40-14.19,46.19-35.86,2.41-8.46-2.51-17.32-10.96-19.75-5.75-1.67-12.04.11-16.06,4.54-1.26,1.37-3.13,1.91-4.93,1.41-1.81-.51-3.15-1.97-3.5-3.8-1.12-5.98-5.44-10.72-11.29-12.37h0c-1.45-.42-2.92-.61-4.36-.61Z" />
      </g>
      <g>
        <path d="M159.15,217.97c-.37.11-.77.11-1.15,0-1.84-.48-45.5-12.41-53.68-42.08-3.4-12.25,3.78-24.93,16.03-28.33,7.12-1.97,14.75-.41,20.52,4.2,2.56-6.91,8.29-12.15,15.4-14.09,12.27-3.37,24.94,3.83,28.33,16.09,8.17,29.64-23.15,62.27-24.49,63.63-.26.28-.6.48-.97.58ZM121.54,151.75c-9.94,2.75-15.78,13.04-13.03,22.99,6.79,24.64,42.26,36.53,49.37,38.69,4.99-5.5,29.33-33.9,22.54-58.52-2.75-9.95-13.04-15.79-22.99-13.05-6.81,1.85-11.97,7.41-13.3,14.34-.16.8-.76,1.45-1.55,1.67h0c-.79.22-1.65-.02-2.2-.63-4.73-5.26-12.02-7.38-18.83-5.48Z" />
        <path d="M158.57,220.78c-.45,0-.91-.07-1.35-.2-1.83-.47-46.87-12.52-55.54-43.96-1.83-6.62-.99-13.57,2.4-19.56,3.39-5.98,8.9-10.29,15.53-12.13,6.82-1.91,14.11-.86,20.11,2.72,3.29-6.15,9-10.76,15.82-12.61,13.69-3.73,27.9,4.31,31.68,18,8.66,31.42-23.79,64.86-25.18,66.26-.55.6-1.32,1.07-2.17,1.29-.43.12-.87.19-1.31.19ZM126.58,153.78c-1.43,0-2.88.19-4.31.59h0c-8.48,2.35-13.47,11.15-11.13,19.64,5.98,21.7,36.38,33.19,45.87,36.28,6.56-7.52,26.76-32.95,20.77-54.66-2.34-8.48-11.19-13.49-19.64-11.14-5.77,1.56-10.22,6.36-11.35,12.22-.37,1.85-1.7,3.29-3.49,3.78-1.8.52-3.7-.04-4.95-1.43-3.07-3.41-7.34-5.27-11.77-5.27Z" />
      </g>
      <g>
        <path d="M199.03,291.66c-32.32,0-58.61-23.05-58.61-51.39v-2.33h117.22v2.33c0,28.33-26.29,51.39-58.61,51.39ZM145.15,242.61c1.41,24.69,25.04,44.39,53.88,44.39s52.48-19.71,53.88-44.39h-107.76Z" />
        <path d="M199.03,294.39c-33.82,0-61.34-24.28-61.34-54.11v-5.06h122.67v5.06c0,29.84-27.51,54.11-61.34,54.11ZM148.15,245.34c2.94,22.05,24.67,38.94,50.88,38.94s47.95-16.88,50.89-38.94h-101.77Z" />
      </g>
    </g>
  </svg>
);
