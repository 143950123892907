import { removeLastSlash, toArray } from '@/lib/utils';
import { postCardTransformer } from './card-post';
import { productCardWordpressTransformer } from './card-product';

import type { TypeProductCardWordpressGraphQL } from './card-product/woordpress';

type Card = { [key: string]: any; uri: string };

/**
 * Transform a list of cards to a list of cards with the correct type
 * @param {Card | Card[]} cards Card or array of cards to transform
 * @returns Array of transformed cards
 */
export const listOfCardsTransformer = (cards: Card | Card[]) =>
  toArray(cards)
    .filter(Boolean)
    .filter(
      (item) =>
        item?.status === 'publish' || 'cpt_banner' in item || 'cptBlog' in item,
    )
    .map((card) => {
      if ('uri' in card) {
        card.uri = removeLastSlash(card.uri);
      }

      if ('status' in card)
        return {
          postType: 'product',
          ...productCardWordpressTransformer(
            card as TypeProductCardWordpressGraphQL,
          ),
        };

      if ('cptBlog' in card)
        return { postType: 'blog', ...postCardTransformer(card) };

      if ('cpt_banner' in card) return { postType: 'banner', ...card };
    })
    .filter(Boolean);
