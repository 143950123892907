import { memo, useEffect, useRef } from 'react';
import { useHits } from 'react-instantsearch';

import { Grid } from '@/molecules';

import { CardProductAlgolia } from '@/components/algolia';
import { useIntersectionObserver } from '@/lib/hooks';
import { gtmPush, normalizeAlgoliaItems } from '@/lib/utils';

import type { TypeProductAlgolia } from '@/lib/graphql/types';
import type { FunctionComponent } from 'react';
import type { TypeGridCardHitsProps } from './types';

const GridAlgolia = ({
  hits,
  className,
  itemListName,
  lg = 4,
  xl = 4,
  sendEvent,
}: TypeGridCardHitsProps & {
  hits: ReturnType<typeof useHits<TypeProductAlgolia>>['hits'];
  sendEvent: ReturnType<typeof useHits<TypeProductAlgolia>>['sendEvent'];
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    freezeOnceVisible: true,
  });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && hits.length) {
      gtmPush({
        event: 'view_item_list',
        ecommerce: {
          items: normalizeAlgoliaItems({ items: hits, itemListName }),
        },
      });
    }
  }, [isVisible, hits, itemListName]);

  return (
    <Grid
      md={4}
      lg={lg}
      xl={xl}
      {...(className && { className })}
      refProp={ref}
    >
      {hits.map((hit: any, index) => (
        <Grid.Item
          colSpanXXS={hit?.card?.double_wide ? 2 : 1}
          colSpanSM={1}
          key={hit?.id ?? index}
        >
          <CardProductAlgolia
            hit={hit}
            sendEvent={sendEvent}
            itemListName={itemListName}
            itemIndex={index + 1}
            numColumns={xl}
          />
        </Grid.Item>
      ))}
    </Grid>
  );
};

const GridAlgoliaMemo = memo(GridAlgolia, (prevProps, nextProps) => {
  const ids = (hits: TypeProductAlgolia[]) => hits.map((hit) => hit.objectID);
  const prevHits = new Set(ids(prevProps.hits));
  const nextHits = new Set(ids(nextProps.hits));

  return (
    prevProps.className === nextProps.className &&
    prevProps.itemListName === nextProps.itemListName &&
    prevProps.lg === nextProps.lg &&
    prevProps.xl === nextProps.xl &&
    nextHits.isSubsetOf(prevHits) &&
    prevHits.isSubsetOf(nextHits)
  );
});

/**
 * GridCardHits
 */
export const GridCardHits: FunctionComponent<TypeGridCardHitsProps> = ({
  className,
  itemListName,
  lg = 4,
  xl = 4,
}: TypeGridCardHitsProps) => {
  const { hits, sendEvent } = useHits<TypeProductAlgolia>();

  return (
    <GridAlgoliaMemo
      {...{ hits, className, itemListName, lg, xl, sendEvent }}
    />
  );
};

GridCardHits.displayName = 'GridCardHits';
