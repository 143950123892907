import { CardProduct } from '@/molecules';

import {
  gtmPushSelectItem,
  normalizeItemServer,
  toCurrency,
} from '@/lib/utils';

import type { productCardWordpressTransformer } from '@/lib/graphql/transformers';
import type { FunctionComponent } from 'react';

type TypeCardProductWordpressProps = {
  product: NonNullable<ReturnType<typeof productCardWordpressTransformer>>;
  type?: 'COMPLETE' | 'SIMPLE';
  itemListName?: string;
  itemIndex?: number;
  numColumns: number;
  editable?: boolean;
  disableFreeShipping?: boolean;
};

/**
 * CardProductWordpressServer
 */
export const CardProductWordpressServer: FunctionComponent<
  TypeCardProductWordpressProps
> = ({
  product,
  type = 'COMPLETE',
  itemListName,
  itemIndex,
  numColumns,
  editable = false,
  disableFreeShipping = false,
}: TypeCardProductWordpressProps) => {
  const {
    type: productType,
    minQtyOrder,
    productID,
    box,
    isConfigurable,
    hasFreeShipping,
    image,
    backgroundColor,
    badge,
    onSale,
    name,
    sellerInfo,
    price,
    regularPrice,
    uri,
    stock,
    primaryCategory,
    discountBadge,
    isConnectif,
    woosbData,
    woosbMinQty,
  } = product;

  const onClick = () => {
    gtmPushSelectItem({
      item: normalizeItemServer(product, itemListName, itemIndex),
      itemListName,
      index: itemIndex,
    });
  };

  const imageSize = Boolean(box)
    ? 'small'
    : numColumns > 4
      ? 'medium'
      : 'large';

  return (
    <CardProduct
      type={productType}
      productID={productID}
      href={uri}
      stock={stock}
      onClick={onClick}
      isConfigurable={isConfigurable}
      editable={type === 'COMPLETE' || editable}
      hasFreeShipping={hasFreeShipping}
      disableFreeShipping={disableFreeShipping}
      gtm={{
        index: itemIndex,
        listName: itemListName,
      }}
      {...(box && { box })}
      {...(minQtyOrder && { minQtyOrder })}
      {...(isConnectif && { isConnectif })}
      {...(woosbData && { woosbData })}
      {...(woosbMinQty && { woosbMinQty })}
    >
      <CardProduct.Image
        src={image.src}
        alt={image.alt || name}
        size={imageSize}
        {...(backgroundColor && { backgroundColor })}
      />

      {primaryCategory && (
        <CardProduct.Category href={primaryCategory.href}>
          {primaryCategory.name}
        </CardProduct.Category>
      )}

      {badge && <CardProduct.Badge {...badge}>{badge.text}</CardProduct.Badge>}

      {type === 'COMPLETE' && onSale && discountBadge && (
        <CardProduct.Badge {...discountBadge}>
          {discountBadge.text}
        </CardProduct.Badge>
      )}

      <CardProduct.Name>{name}</CardProduct.Name>

      {sellerInfo && sellerInfo?.name && (
        <CardProduct.Seller
          {...(sellerInfo?.slug && {
            slug: sellerInfo.slug,
          })}
        >
          {sellerInfo.name}
        </CardProduct.Seller>
      )}

      {type === 'COMPLETE' && onSale && regularPrice && !isConfigurable && (
        <CardProduct.PriceRegular>
          {toCurrency(regularPrice)}
        </CardProduct.PriceRegular>
      )}

      {type === 'COMPLETE' && price && !isConfigurable && (
        <CardProduct.PriceDiscounted onSale={onSale}>
          {toCurrency(price)}
        </CardProduct.PriceDiscounted>
      )}
    </CardProduct>
  );
};

CardProductWordpressServer.displayName = 'CardProductWordpressServer';
