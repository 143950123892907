import type { FunctionComponent } from 'react';
import type { RadioProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const RadioAtom: FunctionComponent<RadioProps> = ({
  className = '',
  value,
  checked = false,
  setValue,
  testid,
  ...props
}: RadioProps) => (
  <div
    className={`flex ${className}`}
    key={value}
    onClick={setValue}
    {...props}
  >
    <div
      className="relative mr-2 flex h-4 w-4 items-center justify-center rounded-full border border-primary-600"
      {...(testid && { 'data-playwright': `rad_${testid}` })}
    >
      {checked && (
        <div className="absolute h-3 w-3 rounded-full bg-secondary-500" />
      )}
    </div>
  </div>
);
