import { useEffect, useRef } from 'react';

import { ConditionalWrapper } from '@/hoc';
import { Link, NextLink, Text } from '@/atoms';

import { useIntersectionObserver } from '@/lib/hooks';
import { gtmPush, normalizeAlgoliaItems } from '@/lib/utils';
import { normalizeItemsServer } from '@/lib/utils/gtm/normalizeItemServer';

import type { productCardWordpressTransformer } from '@/lib/graphql/transformers';
import type { FunctionComponent } from 'react';
import type {
  ProductAlgolia,
  ProductsFromAlgolia,
  TypeProductBlockProps,
} from './types';

const isAlgoliaProducts = (
  items: TypeProductBlockProps['productos'],
): items is ProductsFromAlgolia =>
  items?.at(0)?.hasOwnProperty('_highlightResult') ?? false;

/**
 * ProductBlock
 */
export const ProductBlock: FunctionComponent<TypeProductBlockProps> = ({
  className = '',
  title,
  description,
  link,
  children,
  itemListName,
  productos,
  headingType = 'h2',
}: TypeProductBlockProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { freezeOnceVisible: true });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && productos) {
      if (isAlgoliaProducts(productos)) {
        gtmPush({
          event: 'view_item_list',
          ecommerce: {
            items: normalizeAlgoliaItems({
              items: productos
                ?.filter(Boolean)
                ?.filter((item) => !('cpt_banner' in item)) as ProductAlgolia[],
              itemListName,
            }),
          },
        });
      } else {
        gtmPush({
          event: 'view_item_list',
          ecommerce: {
            items: normalizeItemsServer({
              items: productos
                ?.filter(Boolean)
                ?.filter((item) => item?.postType === 'product') as NonNullable<
                ReturnType<typeof productCardWordpressTransformer>
              >[],
              itemListName,
            }),
          },
        });
      }
    }
  }, [isVisible]);

  return (
    <div
      className={`[&_>_:not(:nth-child(1)):last-child]:mt-4 md:[&_>_:not(:nth-child(1)):last-child]:mt-8 ${className}`}
      ref={ref}
    >
      <ConditionalWrapper
        condition={(Boolean(title) && Boolean(link)) || Boolean(description)}
        wrapper={(children) => (
          <>
            {(title || link) && (
              <ConditionalWrapper
                condition={Boolean(title) && Boolean(link)}
                wrapper={(children) =>
                  link ? (
                    <div className="flex flex-row items-center justify-between gap-8 md:gap-12">
                      <NextLink href={link.href}>
                        <a>{children}</a>
                      </NextLink>

                      <NextLink href={link.href} passHref>
                        <Link
                          size={link.size}
                          type={link.style}
                          className="flex-shrink-0"
                        >
                          {link.label}
                        </Link>
                      </NextLink>
                    </div>
                  ) : null
                }
              >
                {children}
              </ConditionalWrapper>
            )}

            {description && (
              <div className="u-body u-body--xl mt-2 text-typo-secondary">
                {description}
              </div>
            )}
          </>
        )}
      >
        {title && (
          <Text as={headingType} className="u-headline u-headline--h2">
            {title}
          </Text>
        )}
      </ConditionalWrapper>

      {children}
    </div>
  );
};
