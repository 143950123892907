import type { TypeBadge } from '@/atoms/badge/types';
import type { TypeBadgeGraphQL, TypeTrueFalseACF } from '@/lib/graphql/types';

export const badgeTransformer = (
  badge?: TypeBadgeGraphQL,
  lifeStyle?: TypeTrueFalseACF | boolean,
): TypeBadge => ({
  outline: Boolean(badge?.outline),
  style: badge?.style ?? 'orange',
  type: badge?.type || 'basic',
  ...(!badge?.outline &&
    badge?.style === 'custom' && {
      backgroundColor: badge.backgroundColor,
    }),
  ...(badge?.style === 'custom' && {
    textColor: badge?.textColor,
  }),
  ...(lifeStyle
    ? {
        roundedBottomLeft: true,
        roundedTopLeft: true,
      }
    : {
        fullRounded: true,
      }),
});
