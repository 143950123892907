import { Fragment, useMemo, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import { Play } from '@/icons';
import { Container, NextLink } from '@/atoms';
import { Button, Grid } from '@/molecules';

import { containerTransformer } from '@/lib/graphql/transformers';
import { removeBaseURL } from '@/lib/utils';

const Modal = dynamic(() => import('@/molecules/modal'), { ssr: true });
const Video = dynamic(() => import('@/organisms/video'), { ssr: true });

const ExperienciasFlexible = ({ bloque, id, className }: any) => {
  const thisRef = useRef<HTMLDivElement | null>(null);
  const [maxRows, setMaxRows] = useState<number>(0);

  useMemo(() => {
    let rows = 0;
    bloque.columns.forEach(
      (element: any) =>
        (rows = rows < element.rows.length ? element.rows.length : rows),
    );
    setMaxRows(rows);
  }, [bloque]);

  const [openModal, setOpenModal] = useState(false);

  return (
    <Container
      id={id}
      wrapper
      padding={`px-0 
      ${bloque.mobilePadding === 'top' ? 'pt-4' : ''}
      ${bloque.mobilePadding === 'bottom' ? 'pb-4' : ''}
      ${bloque.mobilePadding === 'both' ? 'py-4' : ''}
      md:py-8`}
      as="section"
      ref={thisRef}
      {...(className && { className })}
    >
      <Grid
        xs={1}
        sm={bloque.columns.length}
        md={bloque.columns.length}
        lg={bloque.columns.length}
        outline={false}
        className="md:!grid-flow-col"
        {...(bloque.height
          ? {
              style: {
                minHeight: bloque.height,
              },
            }
          : {})}
      >
        {bloque.columns.map((column: any) =>
          column.rows.map((row: any, index: number) => (
            <Fragment key={index}>
              <Grid.Item
                key={index}
                {...(column.rows.length === 1
                  ? {
                      rowSpanXS: maxRows as any,
                    }
                  : {})}
              >
                <Container
                  {...containerTransformer({
                    imagenBase: row?.imagenBase,
                    overlay: row?.overlay,
                    srcset: row?.srcset ?? [],
                    justify: row?.justify,
                    align: row?.align,
                  })}
                  style={{
                    minHeight: row.minHeight,
                    backgroundColor: row?.colorDeFondo,
                  }}
                  padding="px-8 py-10 md:px-11"
                  className={`h-full ${row.textColor === 'light' && 'text-typo-alternative'}`}
                >
                  <div className={`flex ${row?.imageLocation} gap-5`}>
                    <Container
                      {...containerTransformer({
                        align: row?.align,
                        textAlign: row?.textAlign,
                        justify: row?.justify,
                      })}
                      padding="p-0"
                    >
                      {row?.pretitle && (
                        <p className="u-actions u-actions--link mb-2">
                          {row.pretitle}
                        </p>
                      )}

                      {row?.titulo && (
                        <div
                          className={`u-headline ${
                            column.rows.length === 1
                              ? 'u-headline--h1'
                              : 'u-headline--h2'
                          } mb-6 block max-w-[400px]`}
                          dangerouslySetInnerHTML={{
                            __html: row.titulo,
                          }}
                        />
                      )}

                      {row?.description && (
                        <p className="u-subtitle u-subtitle--m mb-6 max-w-[300px]">
                          {row.description}
                        </p>
                      )}

                      {row.buttonType === 'link' &&
                        row.linkButton.link?.url &&
                        row.linkButton.label && (
                          <NextLink
                            href={
                              removeBaseURL(row.linkButton.link?.url) || '/'
                            }
                            passHref
                          >
                            <Button
                              as="a"
                              negative={row.linkButton.negativo}
                              variant={row.linkButton.style}
                            >
                              {row.linkButton.label}
                            </Button>
                          </NextLink>
                        )}

                      {row.buttonType === 'video' &&
                        row.videoButton.video.videoid &&
                        (row.videoButton.contentType === 'label' ? (
                          <Button
                            size={row.videoButton.size}
                            variant={row.videoButton.variant}
                            onClick={() => {
                              setOpenModal(true);
                            }}
                          >
                            {row.videoButton.label}
                          </Button>
                        ) : (
                          <button
                            className="relative"
                            onClick={() => {
                              setOpenModal(true);
                            }}
                          >
                            <Play
                              color={
                                row.videoButton.variant === 'primary'
                                  ? '#262626'
                                  : row.videoButton.variant === 'secondary'
                                    ? '#FFFFFF'
                                    : '#F68217'
                              }
                              width={64}
                              height={64}
                              className="cursor-pointer"
                            />
                          </button>
                        ))}

                      {row.buttonType === 'newsletter' &&
                        row.newsletterButton.label && (
                          <Button
                            size={row.newsletterButton.size}
                            variant={row.newsletterButton.style}
                            negative={row.newsletterButton.negativo}
                            className="button-lightbox-popin"
                          >
                            {row.newsletterButton.label}
                          </Button>
                        )}
                    </Container>

                    {row.image && (
                      <div className="self-center">
                        <Image
                          src={row.image.sourceUrl}
                          alt={row.image.altText}
                          width={row.image.mediaDetails.width}
                          height={row.image.mediaDetails.height}
                        />
                      </div>
                    )}
                  </div>
                </Container>
              </Grid.Item>

              {row.buttonType === 'video' && (
                <Modal
                  key={`modal_${column}_${index}`}
                  open={openModal}
                  setOpen={setOpenModal}
                  noMaxWidthDesktop
                >
                  <div className="sm:min-w-[360px] lg:min-w-[640px]">
                    <Video
                      type={row.videoButton.video.type}
                      videoId={row.videoButton.video.videoid}
                    />
                  </div>
                </Modal>
              )}
            </Fragment>
          )),
        )}
      </Grid>
    </Container>
  );
};

export default ExperienciasFlexible;

ExperienciasFlexible.displayName = 'FlexibleExperiencias';
