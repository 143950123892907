import { Form, Formik } from 'formik';

import { Button } from '@/molecules';

import { DirectionSchema, updateDirection } from '@/lib/utils/forms';
import { DirectionFormFields } from './DirectionFormFields';

import type { CustomerDirectionForm } from '@/lib/utils/forms';
import type { FormikHelpers } from 'formik';

type DirectionsForm = {
  initialValues?: CustomerDirectionForm;
  allDirections: CustomerDirectionForm[];
  customerID: string;
  title: string | null;
  type: 'ADD' | 'UPDATE';
  onSuccess?: (
    values: CustomerDirectionForm,
    helpers: FormikHelpers<CustomerDirectionForm>,
  ) => void;
  onError?: (
    values: CustomerDirectionForm,
    helpers: FormikHelpers<CustomerDirectionForm>,
    error: any,
  ) => void;
  onCancel?: () => void;
};

const INITIAL_VALUES: CustomerDirectionForm = {
  shippingStreet: '',
  shippingCity: '',
  shippingApartment: '',
  shippingProvince: '',
  shippingPostCode: '',
  shippingPhone: '',
  key: '',
  isDefault: false,
};

export const DirectionForm = ({
  initialValues = INITIAL_VALUES,
  allDirections,
  title,
  type,
  customerID,
  onSuccess,
  onError,
  onCancel,
}: DirectionsForm) => {
  const onSubmit = async (
    values: CustomerDirectionForm,
    helpers: FormikHelpers<CustomerDirectionForm>,
  ) => {
    helpers.setStatus({ error: false });

    let nextIndex = '0';
    if (type === 'ADD') {
      nextIndex = String(
        Number(allDirections[allDirections.length - 1]?.key || 0) + 1,
      );
    } else if (type === 'UPDATE') {
      nextIndex = values.key;
    }

    return updateDirection(values, nextIndex, customerID)
      .then(() => {
        onSuccess && onSuccess(values, helpers);
      })
      .catch((error) => {
        onError && onError(values, helpers, error);
      });
  };

  return (
    <>
      {title && <h2 className="u-headline u-headline--h2 mb-8">{title}</h2>}

      <Formik
        initialValues={initialValues}
        initialStatus={{
          error: false,
        }}
        onSubmit={onSubmit}
        validateOnMount
        validationSchema={DirectionSchema}
      >
        {({ errors, isValid, isSubmitting, status }) => (
          <Form noValidate>
            <DirectionFormFields errors={errors} />

            {status.error && (
              <p className="u-label u-label--overlline my-4 text-status-error">
                Error al guardar los datos, prueba otra vez en un rato.
              </p>
            )}

            <div className="mt-4 flex items-center gap-4">
              <Button
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
                size="normal"
                variant="primary"
                type="submit"
              >
                CONTINUAR
              </Button>

              {allDirections.length > 0 && (
                <button
                  disabled={isSubmitting}
                  onClick={onCancel}
                  className="u-label u-label--s underline hover:opacity-50 disabled:opacity-30"
                >
                  Cancelar
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
