export const config = {
  forms: {
    christmas: 809739,
    joinBigcrafters: 3795,
    acelerateCraftsmen: 800536,
    contact: 3222,
    employeeContact: 1122889,
    getOldOrders: 828594,
    modifySubscription: 2019,
    cancelOrder: 1932,
    requestInvoice:
      process.env.NEXT_PUBLIC_BACK_URL === 'https://admin.bigcrafters.com'
        ? 1068008
        : 987297,
    companyGifts: 1073067,
    cancelTribuSubscription:
      process.env.NEXT_PUBLIC_BACK_URL === 'https://admin.bigcrafters.com'
        ? 1117294
        : 988205,
  },
  maxProducts: 50,
  maxProductsSelectProductPage: 7,
  weightMaxCart: 85,
  sitemap: {
    extraConfig: {
      changefreq: 'daily',
      priority: 0.7,
    },
    items: [
      'brands',
      'categories',
      'categories-attribute',
      'pages',
      'products',
      'sellers',
      'giveaways',
    ],
  },
  session: {
    woocommerce: 'woo-session',
  },
  idPageEmployees: 1059337,
  apps: {
    ios: {
      id: 6449741667,
      url: 'https://apps.apple.com/us/app/bigcrafters/id6449741667',
    },
  },
  consts: {
    LOCAL_STORAGE_EMPLOYEE_REDIRECT: 'employee-redirect',
  },
} as const;
