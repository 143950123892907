import { useContext } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/future/image';

import { ConditionalWrapper } from '@/hoc';
import * as Icons from '@/icons';
import { Container, NextLink } from '@/atoms';
import { IconButton, Prose } from '@/molecules';
import { FlexibleContent } from '@/templates';

import { InitPropsProvider } from '@/components/providers/initPropsProvider';
import { gtmPush } from '@/lib/utils';

import type { InitialPropsProvider } from '@/components/providers/initPropsProvider';

const BeerSubmarine = dynamic(
  () => import('@/assets/images/beer-submarine.svg'),
  { ssr: false },
);

const FooterIcons = ({
  trustIcon,
  trustIconLink,
  appsIcons,
  payments = [],
  socialNetworks = [],
  className,
}: InitialPropsProvider['options'] & { className: string }) => (
  <div className={`justify-start ${className}`}>
    <div className="flex flex-wrap items-center gap-4">
      {trustIcon && (
        <ConditionalWrapper
          condition={!!trustIconLink}
          wrapper={(children) => (
            <a
              href={trustIconLink}
              className="shrink-0"
              target="_blank"
              rel="noreferrer"
            >
              {children}
            </a>
          )}
        >
          <Image
            src={trustIcon.sourceUrl}
            width={50}
            height={50}
            alt={trustIcon.altText || 'Logo Confianza Online'}
            className="bg-contain bg-center"
          />
        </ConditionalWrapper>
      )}

      {payments?.map((item) => {
        const Icon = Icons[item as keyof typeof Icons];

        return Icon ? (
          <div key={item}>
            <span className="sr-only">{item}</span>
            <Icon
              aria-hidden="true"
              width={25}
              height={30}
              {...(item === 'GooglePayDark' && { width: 40, height: 40 })}
              {...(item === 'ApplePay' && { width: 50 })}
            />
          </div>
        ) : null;
      })}
    </div>

    <div className="flex flex-wrap items-center gap-6 md:justify-center">
      {appsIcons?.map((item) => {
        const Icon = Icons[item.icon as keyof typeof Icons];

        return Icon ? (
          <ConditionalWrapper
            key={item.icon}
            condition={!!item?.link}
            wrapper={(children) => (
              <a
                target="_blank"
                href={item.link}
                className="flex items-center gap-2"
                rel="noreferrer"
                onClick={() =>
                  gtmPush({
                    event: 'select_content',
                    eventData: {
                      content_type: 'click_footer',
                      label: item.icon,
                    },
                  })
                }
              >
                {children}
              </a>
            )}
          >
            <span className="sr-only">{item.icon}</span>
            <Icon className="shrink-0" aria-hidden="true" />
            {item?.text && <p className="u-body u-body--xs">{item.text}</p>}
          </ConditionalWrapper>
        ) : null;
      })}
    </div>

    <div className="flex flex-wrap items-center gap-6 md:justify-center">
      {socialNetworks?.map((item) => {
        const Icon = Icons[item.icon as keyof typeof Icons];

        return Icon ? (
          <a target="_blank" key={item.icon} href={item.link} rel="noreferrer">
            <span className="sr-only">{item.icon}</span>
            <Icon className="h-6 w-6 shrink-0" aria-hidden="true" />
          </a>
        ) : null;
      })}
    </div>
  </div>
);

export const Footer = () => {
  const propsProvider = useContext(InitPropsProvider);
  const menus = propsProvider?.menus;
  const legal = menus?.legal;
  const col1 = menus?.recommended;
  const col2 = menus?.about;
  const col3 = menus?.support;
  const col4 = menus?.extra;
  const bcOptions = propsProvider?.options;
  const copyright = bcOptions?.copyright;
  const newsletter = bcOptions?.newsletter;
  const flexibleContent = bcOptions?.footerContent ?? [];
  const legalText = bcOptions?.legalText;
  const logo = bcOptions?.logo;

  return (
    <footer aria-labelledby="footer-heading">
      <p id="footer-heading" className="sr-only">
        Footer
      </p>

      <Container wrapper>
        <div className="grid grid-cols-2 gap-x-8 gap-y-6 md:grid-cols-3 lg:grid-cols-[375px_repeat(4,_1fr)] lg:gap-y-4">
          <div className="col-span-full grid grid-cols-2 gap-x-8 gap-y-6 md:grid-cols-3 lg:col-span-1 lg:row-span-full lg:grid-cols-1 lg:gap-y-4">
            <div className="col-span-2 md:order-2 md:col-span-1 md:flex md:items-end lg:col-span-2 lg:[align-items:normal]">
              <div className="mb-7 max-w-[375px]">
                {logo && <Image {...logo} alt="Logo Bigcrafters" />}
              </div>
            </div>

            <div className="col-span-2 lg:order-2">
              <div className="grid max-w-[500px] grid-cols-3 gap-x-5 gap-y-4">
                <BeerSubmarine
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  className="mx-auto max-w-[100px] lg:row-span-2"
                />

                <div className="col-span-2 flex flex-col justify-center gap-4">
                  {newsletter?.title && (
                    <span className="u-subtitle u-subtitle@mobile--l">
                      {newsletter.title}
                    </span>
                  )}

                  {newsletter?.description && (
                    <p className="u-body u-body--s text-typo-secondary">
                      {newsletter.description}
                    </p>
                  )}
                </div>

                <div className="col-span-full lg:col-span-2">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    id="newsletter_form"
                    className="w-full"
                    name="newsletter_form"
                    method="POST"
                  >
                    <label className="relative block w-full">
                      <input
                        id="newsletter_email"
                        type="email"
                        placeholder="Tu correo electrónico"
                        className="w-full p-4 pr-16"
                      />

                      <IconButton
                        type="submit"
                        size="small"
                        className="button-lightbox-popin absolute right-4 top-1/2 -translate-y-1/2"
                        aria-label="Enviar"
                      >
                        <Icons.ArrowRight width={20} height={14} />
                      </IconButton>
                    </label>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <FooterIcons
            className="col-span-full flex flex-col gap-y-5 md:hidden"
            {...bcOptions}
          />

          <div className="col-span-2 flex flex-col gap-4 xs:col-span-1">
            {col1?.name && (
              <span className="u-subtitle u-subtitle@mobile--l">
                {col1?.name}
              </span>
            )}

            {col1?.items && (
              <ul role="list" className="flex flex-col gap-4 lg:gap-6">
                {col1?.items.map((item, index) => (
                  <li key={index}>
                    <NextLink href={item.url}>
                      <a
                        className="u-actions u-actions--link"
                        onClick={() =>
                          gtmPush({
                            event: 'select_content',
                            eventData: {
                              content_type: 'click_footer',
                              label: item.label,
                            },
                          })
                        }
                      >
                        {item.label}
                      </a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="col-span-2 flex flex-col gap-4 xs:col-span-1">
            {col2?.name && (
              <span className="u-subtitle u-subtitle@mobile--l">
                {col2?.name}
              </span>
            )}

            {col2?.items && (
              <ul role="list" className="flex flex-col gap-4 lg:gap-6">
                {col2?.items?.map((item, index) => (
                  <li key={index}>
                    <NextLink href={item.url}>
                      <a
                        className="u-actions u-actions--link"
                        onClick={() =>
                          gtmPush({
                            event: 'select_content',
                            eventData: {
                              content_type: 'click_footer',
                              label: item.label,
                            },
                          })
                        }
                      >
                        {item.label}
                      </a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="col-span-2 flex flex-col gap-4 xs:col-span-1">
            {col3?.name && (
              <span className="u-subtitle u-subtitle@mobile--l">
                {col3?.name}
              </span>
            )}

            {col3?.items && (
              <ul role="list" className="flex flex-col gap-4 lg:gap-6">
                {col3?.items?.map((item, index) => (
                  <li key={index}>
                    <NextLink href={item.url}>
                      <a
                        className="u-actions u-actions--link"
                        onClick={() =>
                          gtmPush({
                            event: 'select_content',
                            eventData: {
                              content_type: 'click_footer',
                              label: item.label,
                            },
                          })
                        }
                      >
                        {item.label}
                      </a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="col-span-2 flex flex-col gap-4 xs:col-span-1">
            {col4?.name && (
              <span className="u-subtitle u-subtitle@mobile--l">
                {col4?.name}
              </span>
            )}

            {col4?.items && (
              <ul role="list" className="flex flex-col gap-4 lg:gap-6">
                {col4?.items?.map((item, index) => (
                  <li key={index}>
                    <NextLink href={item.url}>
                      <a
                        className="u-actions u-actions--link"
                        onClick={() =>
                          gtmPush({
                            event: 'select_content',
                            eventData: {
                              content_type: 'click_footer',
                              label: item.label,
                            },
                          })
                        }
                      >
                        {item.label}
                      </a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <FooterIcons
          className="mt-6 hidden gap-x-4 md:grid md:grid-cols-3 md:grid-rows-1"
          {...bcOptions}
        />

        {legalText && (
          <Prose as="div" className="u-wrapper pt-5" html={legalText} />
        )}
      </Container>

      {flexibleContent && (
        <Container
          wrapper
          backgroundColor="bg-bg-tertiary"
          padding="p-0"
          className="text-typo-alternative"
        >
          <FlexibleContent
            type="BcGeneralSettings"
            typeFlexible="Ajustesgenerales"
            flexible={flexibleContent}
          />
        </Container>
      )}

      <Container
        wrapper
        padding="px-4 py-4"
        backgroundColor="bg-bg-tertiary"
        className="text-typo-alternative"
      >
        <div className="u-actions u-actions--link flex flex-col justify-center gap-10 text-center lg:flex-row lg:justify-between lg:text-left">
          <ul role="list" className="flex flex-col gap-4 lg:flex-row lg:gap-12">
            {legal?.items?.map((item, index) => (
              <li key={index}>
                <NextLink href={item.url}>
                  <a
                    onClick={() =>
                      gtmPush({
                        event: 'select_content',
                        eventData: {
                          content_type: 'click_footer',
                          label: item.label,
                        },
                      })
                    }
                  >
                    {item.label}
                  </a>
                </NextLink>
              </li>
            ))}
          </ul>

          {copyright}
        </div>
      </Container>
      <Container
        wrapper
        padding="px-4 py-4"
        backgroundColor="bg-bg-tertiary"
        className="border-t border-primary-600 text-typo-alternative"
      >
        <div className="u-body u-body--s flex flex-col justify-center gap-10 text-center text-primary-400 lg:flex-row lg:justify-between lg:text-left">
          Estrella Galicia recomienda el consumo responsable. Prohibida la venta
          o suministro de bebidas alcohólicas a menores de 18 años.
        </div>
      </Container>
    </footer>
  );
};
