import { WORDPRESS_MUTATION } from '../enums';
import APClient from '../utils';

export const sendLogs = ({
  type,
  message,
}: {
  type: 'user-log-in' | string;
  message: { [key: string]: any };
}) =>
  new Promise((resolve, reject) => {
    APClient.mutate({
      mutation: WORDPRESS_MUTATION.SEND_ERROR_LOG,
      variables: {
        errorJson: JSON.stringify({
          url: window.location.href,
          browser: navigator?.userAgent,
          device: navigator?.platform,
          ...message,
        }),
        errorType: type,
      },
    })
      .then((data) => {
        if (!data.errors || data.errors.length === 0) resolve(data.data);
        else reject(data.errors);
      })
      .catch((error) => reject(error));
  });
