import { Field, FieldArray } from 'formik';

import CheckboxAtom from '@/atoms/checkbox';
import { InputAtom } from '@/atoms/input/Input';
import { LabelAtom } from '@/atoms/label/Label';

import type { FieldArrayRenderProps, FieldProps } from 'formik';
import type { FunctionComponent } from 'react';
import type { CheckBoxProps } from '../types';

/**
 * Primary UI component for user interaction
 */
export const Checkbox: FunctionComponent<CheckBoxProps> = ({
  className = '',
  wrapperClassName = '',
  name,
  error,
  values,
  onSetValue,
  disabled = false,
  hidden = false,
  testid,
}: CheckBoxProps) => {
  const handleChange = (
    // TODO: Añadir tipos
    field: any,
    item: any,
    arrayHelpers: any,
    index: number,
  ) => {
    const found = field.value && field.value.includes(item.value);
    if (found) {
      arrayHelpers.remove(
        // TODO: Añadir tipos
        field.value.findIndex((el: any) => el == item.value),
      );
    } else {
      arrayHelpers.insert(index, item.value);
    }

    onSetValue && onSetValue(!found);
  };

  return (
    <FieldArray name={name}>
      {(arrayHelpers: FieldArrayRenderProps) => (
        <>
          <div {...(className && { className: className })}>
            {
              // TODO: Añadir tipos
              values.map((item: any, index) => (
                <Field name={name} value={item.value} key={index}>
                  {({ field, meta: { touched } }: FieldProps) => {
                    const checked =
                      field.value && field.value.includes(item.value);

                    return (
                      <div
                        className={`mb-2 flex w-full aria-disabled:pointer-events-none aria-disabled:opacity-20 ${
                          typeof wrapperClassName === 'function'
                            ? wrapperClassName(checked)
                            : wrapperClassName
                        }`}
                        aria-disabled={disabled}
                      >
                        <CheckboxAtom
                          checked={checked}
                          setValue={() => {
                            handleChange(field, item, arrayHelpers, index);
                          }}
                          disabled={disabled}
                          className={hidden ? '!hidden' : ''}
                          {...(testid && { testid })}
                        />

                        <InputAtom
                          hidden
                          name={name}
                          type="checkbox"
                          value={field.value}
                          checked={checked}
                          touched={touched}
                          error={error}
                          field={field}
                          handleBlur={field.onBlur}
                          handleChange={field.onChange}
                          id={item.name}
                          disabled={disabled}
                        />

                        <LabelAtom
                          label={
                            typeof item.label === 'function'
                              ? item.label(checked)
                              : item.label
                          }
                          setValue={() => {
                            handleChange(field, item, arrayHelpers, index);
                          }}
                          className="u-body u-body--xs cursor-pointer aria-disabled:pointer-events-none hover:cursor-pointer"
                          name={item.name}
                          aria-disabled={disabled}
                        />
                      </div>
                    );
                  }}
                </Field>
              ))
            }
          </div>

          {error && (
            <p className="u-label u-label--xs mt-2 text-status-error">
              {error}
            </p>
          )}
        </>
      )}
    </FieldArray>
  );
};
