import { normalizeAlgoliaItem } from './normalizeAlgoliaItem';

import type { TypeProductAlgolia } from '@/lib/graphql/types';
import type { GTMProduct } from './products';

export const normalizeAlgoliaItems = ({
  items,
  itemListName,
}: {
  items: TypeProductAlgolia[];
  itemListName?: string;
}): GTMProduct[] =>
  items.map((item, index) =>
    normalizeAlgoliaItem({ item, itemListName, index: index + 1 }),
  );
