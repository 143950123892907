import { useEffect, useRef, useState } from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Form, Formik } from 'formik';

import { Button, Checkbox } from '@/molecules';
import { DirectionForm } from '@/organisms/forms/direction-forms';

import { DEFAULT_TOAST_OPTIONS_WARNING } from '@/components/providers';
import { useCart, useCheckout, useToastDispatch } from '@/lib/hooks';
import { routes } from '@/lib/routes';
import {
  checkoutDirectionToFormDirection,
  getProvinceLabelFromValue,
  gtmPush,
  normalizeProducts,
  priceToNumber,
} from '@/lib/utils';
import { DirectionFormFields } from '../direction-forms/DirectionFormFields';
import { DirectionSelector } from '../direction-forms/DirectionSelector';
import { GiftForm } from './GiftForm';
import { FORM_INITIAL_VALUES, FormSchema } from './utils';

import type { CustomerDirection } from '@/lib/utils';
import type { FormikHelpers, FormikProps } from 'formik';
import type { CheckoutDirectionFormValues } from './utils';

const enum FORM_STATE {
  SELECT, // The customer has directions saved and we display them.
  UPDATE, // The customer wants to modify an existing direction.
  NEW, // The customer wants to add a new direction
  GUEST, // The customer is not logged in so they can only set a direction for that order and not save them.
}

export const FORM_STORAGE_KEY = 'checkout-fields';

const saveFormState = (formValues: CheckoutDirectionFormValues) => {
  // We store the current state of the form so we can restore it when the user navigates back from the confirmation page or adds/updates a direction.
  // We save it in the sessionStorage and clean the values on the checkout context when a new checkout "session" begins.

  sessionStorage.setItem(FORM_STORAGE_KEY, JSON.stringify(formValues));
};

export const CheckoutDirectionForm = () => {
  const {
    checkout,
    isLoading: checkoutIsLoading,
    removeDirection,
    addDirection,
    updateDirection,
    setCheckoutData,
  } = useCheckout();
  const session = useSession();
  const router = useRouter();
  const { toast } = useToastDispatch();
  const { cart } = useCart();

  const formRef = useRef<FormikProps<CheckoutDirectionFormValues>>(null);

  const [formState, setFormState] = useState<FORM_STATE>(FORM_STATE.SELECT);
  const seletedDirectionToUpdate = useRef<CustomerDirection | null>(null);

  const giftData = useRef<Partial<CheckoutDirectionFormValues | null>>(null);

  let indexDefaultDirection = checkout.allDirections.findIndex(
    (checkout) => checkout.isDefault,
  );

  if (indexDefaultDirection === -1) {
    indexDefaultDirection = 0;
  }

  useEffect(() => {
    if (session?.status === 'unauthenticated') {
      setFormState(FORM_STATE.GUEST);
      return;
    }

    if (session?.status === 'authenticated') {
      if (!checkoutIsLoading && checkout?.allDirections?.length === 0) {
        setFormState(FORM_STATE.NEW);
      } else {
        setFormState(FORM_STATE.SELECT);
      }
    }
  }, [checkout?.allDirections?.length, session?.status, checkoutIsLoading]);

  useEffect(() => {
    if (!formRef.current) return;
    if (checkoutIsLoading) return;
    if (formState !== FORM_STATE.SELECT) return;

    const giftFields = sessionStorage.getItem(FORM_STORAGE_KEY);
    if (!giftFields) return;

    formRef.current.setValues(JSON.parse(giftFields));
  }, [checkout.isGift, checkoutIsLoading, formState, indexDefaultDirection]);

  if (formState === FORM_STATE.UPDATE || formState === FORM_STATE.NEW) {
    let title;
    let initialValues = undefined;
    const type = formState === FORM_STATE.NEW ? 'ADD' : 'UPDATE';

    if (formState === FORM_STATE.UPDATE) {
      title = 'Actualiza tu dirección de envío';

      if (seletedDirectionToUpdate.current) {
        initialValues = checkoutDirectionToFormDirection(
          seletedDirectionToUpdate.current,
        );
      }
    } else {
      title = 'Añadir nueva dirección';
    }

    return (
      <DirectionForm
        type={type}
        initialValues={initialValues}
        title={title}
        allDirections={checkout.allDirections.map(
          checkoutDirectionToFormDirection,
        )}
        customerID={checkout.customerID}
        onCancel={() => setFormState(FORM_STATE.SELECT)}
        onSuccess={(values) => {
          if (formState === FORM_STATE.NEW) {
            addDirection(values);
          } else {
            updateDirection(values);
          }

          setFormState(FORM_STATE.SELECT);
        }}
        onError={(_, helper) => {
          helper.setStatus({ error: true });
        }}
      />
    );
  }

  const onSubmit = (
    values: CheckoutDirectionFormValues,
    helpers: FormikHelpers<CheckoutDirectionFormValues>,
  ) => {
    helpers.setSubmitting(true);

    const isGuest = formState === FORM_STATE.GUEST;
    const isGift = values.isGift.length > 0;

    const sameDirectionAsShipping = isGuest || (!isGuest && !isGift);

    const seletedDirection = checkout.allDirections[values.direction];

    // ~~~~~~~~~~ Billing Fileds ~~~~~~~~~~ //

    const billingApartment = isGuest
      ? values.shippingApartment
      : seletedDirection?.address2 || '';

    const billingCity = isGuest
      ? values.shippingCity
      : seletedDirection?.city || '';

    const billingPostCode = isGuest
      ? values.shippingPostCode
      : seletedDirection?.postCode || '';

    const billingPhone = isGuest
      ? values.shippingPhone
      : seletedDirection?.phone || '';

    const billingProvince = isGuest
      ? getProvinceLabelFromValue(values.shippingProvince)
      : seletedDirection?.province || '';

    const billingStreet = isGuest
      ? values.shippingStreet
      : seletedDirection?.address1 || '';

    // ~~~~~~~~~~ Shipping Fileds ~~~~~~~~~~ //

    const shippingStreet = isGuest
      ? values.shippingStreet
      : isGift
        ? values.giftStreet
        : seletedDirection?.address1 || '';

    const shippingApartment = isGuest
      ? values.shippingApartment
      : isGift
        ? values.giftApartment
        : seletedDirection?.address2 || '';

    const shippingCity = isGuest
      ? values.shippingCity
      : isGift
        ? values.giftCity
        : seletedDirection?.city || '';

    const shippingPostCode = isGuest
      ? values.shippingPostCode
      : isGift
        ? values.giftCp
        : seletedDirection?.postCode || '';

    const shippingPhone = isGuest
      ? values.shippingPhone
      : isGift
        ? values.giftPhone
        : seletedDirection?.phone || '';

    const shippingProvince = isGuest
      ? getProvinceLabelFromValue(values.shippingProvince)
      : isGift
        ? getProvinceLabelFromValue(values.giftProvince)
        : seletedDirection?.province || '';

    setCheckoutData({
      sameDirectionAsShipping,
      billing_apartment: billingApartment,
      billing_city: billingCity,
      billing_cp: billingPostCode,
      billing_phone: billingPhone,
      billing_province: billingProvince,
      billing_street: billingStreet,

      shipping_apartment: shippingApartment,
      shipping_city: shippingCity,
      shipping_cp: shippingPostCode,
      shipping_phone: shippingPhone,
      shipping_province: shippingProvince,
      shipping_street: shippingStreet,

      shipping_firstname: isGift ? values.giftName : '',
      shipping_lastname: isGift ? values.giftApellidos : '',

      isGift,
      giftEmail: isGift ? values.giftEmail : '',
      notas: isGift ? values.giftNota : '',
    });

    gtmPush({
      event: 'add_shipping_info',
      ecommerce: {
        currency: 'EUR',
        value: priceToNumber(cart.total),
        items: normalizeProducts({ cart, itemListName: 'cart' }),
      },
    });

    saveFormState(values);

    router.push(routes.checkout.confirmation);
  };

  return (
    <>
      <h2 className="u-headline u-headline--h2 mb-8">
        Introduce tu Dirección de envío
      </h2>

      <Formik
        initialValues={{
          ...FORM_INITIAL_VALUES,
          direction: indexDefaultDirection,
          isGift: !checkoutIsLoading && checkout.isGift ? ['true'] : [],
          ...(giftData.current && {
            ...giftData.current,
          }),
        }}
        innerRef={formRef}
        enableReinitialize
        onSubmit={onSubmit}
        validateOnMount
        validationSchema={FormSchema(session)}
      >
        {({ errors, isValid, values: formValues, isSubmitting }) => (
          <Form noValidate>
            {formState === FORM_STATE.SELECT && (
              <>
                {formValues.isGift.length > 0 && (
                  <>
                    <GiftForm />

                    <h3 className="u-headline u-headline--h3 mb-8 mt-12">
                      elige una dirección de comprador
                    </h3>
                  </>
                )}

                <DirectionSelector
                  isLoading={checkoutIsLoading || session.status === 'loading'}
                  directions={checkout.allDirections}
                  errors={errors}
                  onUpdate={(seletedDirection) => {
                    seletedDirectionToUpdate.current = seletedDirection;

                    saveFormState(formValues);
                    setFormState(FORM_STATE.UPDATE);
                  }}
                  onNewDirection={() => {
                    saveFormState(formValues);
                    setFormState(FORM_STATE.NEW);
                  }}
                  onDeleteSucess={(directionKey) => {
                    removeDirection(directionKey);
                  }}
                  onDeleteError={() => {
                    toast(
                      'Ha ocurrido un error al intentar borrar la dirección, intentelo de nuevo mas tarde',
                      DEFAULT_TOAST_OPTIONS_WARNING,
                    );
                  }}
                />

                {formValues.isGift.length === 0 && (
                  <Checkbox
                    name="isGift"
                    className="mt-4 w-full"
                    values={[
                      {
                        value: 'true',
                        label: 'Es para regalar',
                      },
                    ]}
                    id="isGift"
                    disabled={checkoutIsLoading || session.status === 'loading'}
                    error={errors.isGift}
                  />
                )}
              </>
            )}

            {formState === FORM_STATE.GUEST && (
              <DirectionFormFields errors={errors} />
            )}

            <Button
              disabled={!isValid || checkoutIsLoading || isSubmitting}
              loading={isSubmitting}
              size="normal"
              variant="primary"
              type="submit"
              className="mt-8"
              testid="submit"
            >
              CONTINUAR
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
