import { useSession } from 'next-auth/react';

type User = {
  email: string;
  emailEncripted: string;
  name: string;
  id: string;
  firstName: string;
  lastName: string;
  customerId: string;
  UID: string;
  isEmployee: boolean;
};

type UseEmployeeReturn =
  | {
      isEmployee: true;
      user: User;
      status: ReturnType<typeof useSession>['status'];
    }
  | {
      isEmployee: false;
      user: null;
      status: ReturnType<typeof useSession>['status'];
    };

/**
 * Hook to check if user is employee.
 *
 * @return {UseEmployeeReturn} { isEmployee: true; user: Session; } | { isEmployee: false; user: null; }
 */
export const useEmployee = (): UseEmployeeReturn => {
  const { data, status } = useSession();
  // TODO: fix type
  const user = data?.user as User;

  return Boolean(user && user?.isEmployee)
    ? { isEmployee: true, user, status }
    : { isEmployee: false, user: null, status };
};
