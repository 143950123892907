import { useEffect, useState } from 'react';

import type { FunctionComponent } from 'react';
import type { InputProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const InputAtom: FunctionComponent<InputProps> = ({
  type,
  field,
  name,
  hidden,
  className = '',
  touched,
  error,
  handleBlur,
  handleChange,
  value,
  disabled = false /* eslint-disable-next-line @typescript-eslint/no-empty-function */,
  handleFocus = () => {},
  maxLength = 524288,
  id,
  testid,
  ...props
}: InputProps) => {
  if (type == 'number') {
    field.value = parseInt(field.value);
  }

  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (type == 'radio' && selected != field.value) {
      setSelected(field.value);
      handleChange(field.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <input
      disabled={disabled}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      type={type}
      value={field.value}
      required
      maxLength={maxLength}
      id={id}
      className={`flex ${hidden ? '!hidden' : ' '} ${touched && error ? 'border-status-error text-status-error' : ' '} ${className}`}
      {...(testid && { 'data-playwright': `inp_${testid}` })}
      {...props}
    />
  );
};
