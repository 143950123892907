import { useEffect } from 'react';
import {
  InstantSearch as AlgoliaInstantSearch,
  useInstantSearch,
} from 'react-instantsearch';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import debounce from 'lodash-es/debounce';
import aa from 'search-insights';

import { gtmPush } from '@/lib/utils';

import type { FunctionComponent } from 'react';
import type { TypeInstantSearchProps } from './types';

const gtmMiddleware = () => {
  const sendEventDebounced = debounce((uiState) => {
    const searchQuery = uiState.hdrwp_product_catalog.query;

    if (searchQuery) {
      gtmPush({
        event: 'search',
        eventData: {
          search_term: searchQuery,
        },
      });
    }
  }, 1500);

  return {
    onStateChange({ uiState }: any) {
      sendEventDebounced(uiState);
    },
    subscribe() {},
    unsubscribe() {},
  };
};

const middleware = createInsightsMiddleware({
  // TODO: fix type error: 'DebouncedFunc<InsightsClient>' is not assignable to type 'ProvidedInsightsClient'
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  insightsClient: debounce(aa, 500),
});

function Middleware() {
  const { addMiddlewares } = useInstantSearch();

  aa('init', {
    appId: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_APP_ID || '',
    apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || '',
  });

  useEffect(() => {
    addMiddlewares(gtmMiddleware);
    addMiddlewares(middleware);
  }, [addMiddlewares]);

  return null;
}

const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_APP_ID ?? '',
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ?? '',
);

const searchClient: any = {
  ...algoliaClient,
  search(requests: any) {
    const shouldIntercept = requests.every(
      ({ params }: any) => !params.query && !params.filters,
    );

    if (shouldIntercept) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

/**
 * InstantSearch
 */
export const InstantSearch: FunctionComponent<TypeInstantSearchProps> = ({
  children,
  ...props
}: TypeInstantSearchProps) => (
  <AlgoliaInstantSearch
    future={{
      preserveSharedStateOnUnmount: false,
      persistHierarchicalRootCount: false,
    }}
    searchClient={searchClient}
    indexName={process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX_NAME ?? ''}
    {...props}
  >
    {children}
    <Middleware />
  </AlgoliaInstantSearch>
);

InstantSearch.displayName = 'InstantSearch';
