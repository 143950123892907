import type { IconType } from './types';

export const Tribu = ({
  className,
  width = 24,
  height = 24,
  color = '#262626',
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill={color}
        d="M12 11a5 5 0 0 1 5 5v6h-2v-6a3 3 0 0 0-2.82-3H12a3 3 0 0 0-3 2.82V22H7v-6a5 5 0 0 1 5-5Zm-6.5 3c.28 0 .55.03.81.1-.17.5-.27 1.03-.3 1.57L6 16v.09a1.5 1.5 0 0 0-.36-.08L5.5 16A1.5 1.5 0 0 0 4 17.36V22H2v-4.5A3.5 3.5 0 0 1 5.5 14Zm13 0a3.5 3.5 0 0 1 3.5 3.5V22h-2v-4.5a1.5 1.5 0 0 0-1.36-1.5h-.14c-.18 0-.34.03-.5.09V16c0-.67-.1-1.3-.3-1.9.25-.07.52-.1.8-.1Zm-13-6a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm13 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm-13 2a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm13 0a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM12 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
