import { useEffect } from 'react';
import debounce from 'lodash-es/debounce';

import type { RefObject } from 'react';

/**
 * Custom hook to handle header position and claim display.
 * @param headerRef Ref of the header.
 * @param claimRef Ref of the parent div holding the claim.
 * @param threshold Minimum number of pixels the user has to scroll before updating the header.
 * @param delay Number of milliseconds to wait after last scroll to update the header.
 * @param withOutSticky If True the hook doesn't execute.
 */
export const useHeaderSticky = (
  headerRef: RefObject<HTMLElement>,
  claimRef: RefObject<HTMLDivElement>,
  threshold: number,
  delay: number,
  withOutSticky: boolean,
) => {
  useEffect(() => {
    if (
      withOutSticky ||
      !window.IntersectionObserver ||
      !headerRef?.current ||
      !claimRef?.current
    ) {
      return;
    }

    let lastPosition = window.scrollY;

    const checkScrollingState = debounce(() => {
      if (headerRef.current && headerRef.current?.style) {
        headerRef.current.style.top = '0px';
      }
    }, delay);

    function handleScroll() {
      if (
        claimRef.current?.style &&
        headerRef.current?.style &&
        window.scrollY === 0
      ) {
        claimRef.current.style.display = 'block';
      }

      if (
        headerRef.current?.style &&
        Math.abs(lastPosition - window.scrollY) >= threshold
      ) {
        if (lastPosition > window.scrollY) {
          headerRef.current.style.top = '0px';
        } else {
          headerRef.current.style.top = `-${headerRef.current.clientHeight}px`;
        }

        lastPosition = window.scrollY;
      }

      checkScrollingState();
    }

    // We have two diferent behaviours in respect as how we handle the appearance of the claim
    // when the header hides. This is because Safari behaves in a diferent way than Chrome and Firefox
    // when handling sticky elements.
    // This still causes weird behaviours in Safari browsers on some edges cases.
    function handleIntersection([entry]: IntersectionObserverEntry[]) {
      if (
        navigator.userAgent.includes('Safari') &&
        !navigator.userAgent.includes('Chrome')
      ) {
        if (entry && entry.intersectionRatio === 0 && window.scrollY > 0) {
          claimRef.current!.style.display = 'none';
        }
      } else {
        if (entry && entry.intersectionRatio > 0 && window.scrollY > 0) {
          claimRef.current!.style.display = 'none';
        }
      }
    }

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '-2px',
      threshold: 0,
    });

    observer.observe(headerRef.current);

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, [claimRef, delay, headerRef, threshold, withOutSticky]);
};
