import { useState } from 'react';
import { oneLine } from 'common-tags';
import { Field } from 'formik';

import { LabelAtom } from '@/atoms/label/Label';
import TextAreaAtom from '@/atoms/text-area';

import type { FieldProps } from 'formik';
import type { FunctionComponent } from 'react';
import type { TextAreaProps } from '../types';

/**
 * Primary UI component for user interaction
 */
export const TextArea: FunctionComponent<TextAreaProps> = ({
  className = '',
  labelClass = '',
  name,
  placeholder,
  error,
  rows = 2,
  cols = 20,
  disabled = false,
}: TextAreaProps) => {
  const [focus, setFocus] = useState(false);

  return (
    <div className={`${className} relative`}>
      <Field name={name}>
        {({ field, meta, form: { setFieldValue } }: FieldProps) => (
          <>
            <div>
              <LabelAtom
                label={placeholder ?? ''}
                name={name}
                className={oneLine`u-body !absolute left-3 z-10 ${
                  focus || field.value ? 'u-label u-label--xs top-1' : 'top-3'
                } ${
                  error && meta.touched
                    ? 'border-status-error text-status-error'
                    : 'text-primary-300'
                } ${labelClass} `}
              />

              <TextAreaAtom
                hidden={false}
                handleFocus={() => {
                  setFocus(true);
                }}
                handleBlur={(e) => {
                  setFocus(false);
                  field.onBlur(e);
                }}
                handleChange={(e) => {
                  field.onChange(e);
                }}
                field={field}
                name={name}
                id={name}
                className={oneLine`relative block h-auto w-full appearance-none rounded-none border px-3 pt-4 outline-none focus:ring-0 sm:text-sm ${
                  error && meta.touched
                    ? 'border-status-error text-status-error focus:border-status-error focus:text-status-error'
                    : 'border-primary-300 text-primary-400 focus:border-typo-primary focus:text-primary-600'
                } `}
                // TODO: Añadir tipos
                setValue={(e: any) => {
                  setFieldValue(name, e.target.value);
                }}
                rows={rows}
                cols={cols}
                disabled={disabled}
              />
            </div>
            {error && meta.touched && (
              <p className="u-label u-label--xs mt-2 text-status-error">
                {error}
              </p>
            )}
          </>
        )}
      </Field>
    </div>
  );
};
