import { useState } from 'react';
import { oneLine } from 'common-tags';
import { Field } from 'formik';

import { OjoCerrado } from '@/icons';
import { InputAtom } from '@/atoms/input/Input';
import { LabelAtom } from '@/atoms/label/Label';

import type { FieldProps } from 'formik';
import type { FunctionComponent } from 'react';
import type { InputProps } from '../types';

/**
 * Primary UI component for user interaction
 */
export const Input: FunctionComponent<InputProps> = ({
  className = '',
  type,
  name,
  placeholder,
  error,
  caption = '',
  onChange /* eslint-disable-next-line @typescript-eslint/no-empty-function */,
  onBlur = () => {},
  maxLength = 524288,
  disabled = false,
  testid,
  ...props
}: InputProps) => {
  // TODO: Hay que añadir que se quiten los estilos del focus
  // cuando este lo pierde al hacer clic fuera del input en
  // text area si funciona correctamente
  const [focus, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={`relative ${className}`}>
      <Field name={name}>
        {({ field, meta, form }: FieldProps) => (
          <>
            <div>
              {type === 'password' && (
                <div
                  className="absolute !right-3 top-3 z-40 cursor-pointer"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <OjoCerrado color={showPassword ? '#262626' : '#A3988F'} />
                </div>
              )}
              <LabelAtom
                label={placeholder ?? ''}
                name={name}
                events={false}
                className={oneLine`absolute left-3 z-10 ${focus || field.value ? 'u-label u-label--xs top-1' : 'u-body top-3'} ${
                  error && meta.touched
                    ? 'border-status-error text-status-error'
                    : 'text-primary-300'
                } `}
              />
              <InputAtom
                hidden={false}
                handleFocus={() => {
                  setFocus(true);
                }}
                handleBlur={(e) => {
                  setFocus(false);
                  form.handleBlur(e);
                  field.onBlur(e);
                  onBlur(e);
                }}
                handleChange={(e) => {
                  field.onChange(e);
                  onChange && onChange(e);
                }}
                value={field.value ? field.value : ''}
                disabled={disabled}
                field={field}
                name={name}
                type={
                  type == 'password' ? (showPassword ? 'text' : type) : type
                }
                touched={meta.touched}
                maxLength={maxLength}
                error={meta.touched && error ? error : ''}
                className="relative block h-12 w-full appearance-none rounded-none border border-primary-300 px-3 pt-4 !text-primary-300 outline-none focus:border-typo-primary focus:!text-primary-600 focus:ring-0 sm:text-sm"
                {...(testid && { testid })}
                {...props}
              />

              {caption !== '' && (
                <span className="u-label u-label--xs pt-2 text-typo-secondary">
                  {caption}
                </span>
              )}
            </div>
            {error && meta.touched && (
              <div
                className="u-label u-label--xs mt-2 text-status-error"
                dangerouslySetInnerHTML={{ __html: error }}
              />
            )}
          </>
        )}
      </Field>
    </div>
  );
};
