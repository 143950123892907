import 'scripts/wdyr';
import '../styles/globals.css';

import { useEffect } from 'react';
import { SessionProvider } from 'next-auth/react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { withPasswordProtect } from '@storyofams/next-password-protect';

import { Error, ErrorBoundary } from '@/pages';

import { LoginComponent } from '@/components/passwordProtect/LoginComponent';
import {
  CartProvider,
  InitPropsProvider,
  ToastProvider,
} from '@/components/providers';
import { useBeforeHistoryChange } from '@/lib/hooks';
import { gtmPushQueuedEvents } from '@/lib/utils';
import HeaderFromSeo from '@/lib/utils/seoHeader';

import type { InitialPropsProvider } from '@/components/providers/initPropsProvider';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { NextRouter } from 'next/dist/client/router';
import type { ReactElement, ReactNode } from 'react';

const AgeGate = dynamic(
  () => import('@/molecules/ageGate/AgeGate').then((mod) => mod.AgeGate),
  { ssr: false },
);

declare global {
  interface Window {
    getInSiteForm(
      id: any,
      styleButton: any,
      styleBody: any,
      styleBox: any,
      styleBoxText: any,
      buttonValue: any,
      fuc: any,
      terminal: any,
      order: any,
      idioma: any,
      k: any,
    ): void;
    oct8ne: any;
    getInSiteForm(): void;
    userGigyaId: string;
    getExpirationMonthInput(a: string, b: string): void; // 👈️ turn off type checking
    itemIndex?: number;
    itemListName?: string;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout<T> = AppProps<T> & {
  Component: NextPageWithLayout<T>;
};

function MyApp({
  Component,
  pageProps: { session, initialProps, pageProps, ...otherProps },
}: // TODO: Añadir tipos
AppPropsWithLayout<{
  initialProps: InitialPropsProvider;
  session: any;
  pageProps: any;
}>) {
  const router = useRouter();

  useEffect(() => {
    const { cjevent } = router.query;

    if (cjevent) {
      fetch(`/api/cjevent/${cjevent}`);
    }
  }, [router]);

  const getLayout = Component.getLayout
    ? (children: ReactNode | ReactNode[]) =>
        Component.getLayout &&
        Component.getLayout({
          children,
          ...pageProps,
          ...initialProps?.options,
          ...otherProps,
        })
    : (children: ReactNode | ReactNode[]) => children;

  useBeforeHistoryChange(() => {
    gtmPushQueuedEvents();
    sessionStorage.setItem('gtmQueueEnable', 'true');
  });

  return (
    <ErrorBoundary fallback={Error} type="Cliente">
      <Script id="consent-mode-v2" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          // Default ad_storage to 'denied'.
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'personalization_storage': 'denied',
            'security_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'wait_for_update': 500
          });
        `}
      </Script>

      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
        `}
      </Script>

      <Oct8neScript router={router} pageProps={pageProps} />

      <HeaderFromSeo seo={pageProps?.seo} />

      <InitPropsProvider.Provider value={initialProps}>
        <SessionProvider session={session} refetchOnWindowFocus={false}>
          {/* TODO: GET TYPE FROM pageProps.initialProps  && pageProps.witCartContent */}
          <ToastProvider>
            <CartProvider>
              {getLayout(
                <Component
                  pageProps={pageProps}
                  initialProps={initialProps}
                  session={session}
                  {...otherProps}
                />,
              )}
            </CartProvider>
          </ToastProvider>
        </SessionProvider>
      </InitPropsProvider.Provider>

      <AgeGate
        showAgeGate={
          pageProps?.pageAgeGate?.showAgeGate === undefined
            ? true
            : !!pageProps.pageAgeGate.showAgeGate
        }
        logo={initialProps?.options?.logo}
      />
    </ErrorBoundary>
  );
}

const Oct8neScript = ({
  router,
  pageProps,
}: {
  router: NextRouter;
  pageProps: any;
}) => {
  useEffect(() => {
    const oct8neId = 'oct8ne-script';

    if (!document.getElementById(oct8neId)) {
      const oct8neScript = document.createElement('script');
      oct8neScript.id = oct8neId;
      oct8neScript.setAttribute('data-nscript', 'afterInteractive');
      oct8neScript.textContent = `
      ${
        router.asPath.includes('/producto/')
          ? `window.oct8ne.currentProduct = {
          id: "${pageProps?.databaseId}",
          thumbnail: "${pageProps?.image?.sourceUrl}"
        };`
          : `window.oct8ne.currentProduct = null`
      }
      `;
      document.body.appendChild(oct8neScript);
    }
    return () => {
      document.getElementById(oct8neId)?.remove();
    };
  }, [pageProps?.databaseId, pageProps?.image?.sourceUrl, router.asPath]);

  return (
    <Script id="oct8ne-script" strategy="afterInteractive">
      {`
        window.oct8ne = document.createElement("script");
        if (window.oct8ne) {
          window.oct8ne.type = "text/javascript";
          window.oct8ne.src = (document.location.protocol == "https:" ? "https://" : "http://") + "static-eu.oct8ne.com/api/v2/oct8ne.js";
          window.oct8ne.server = "backoffice-eu.oct8ne.com/";
          window.oct8ne.async = true;
          window.oct8ne.license = "DE29009BD8D794FAC2053D223DB890E5";
          window.oct8ne.baseUrl = 'https://bigcrafters.com';
          window.oct8ne.checkoutUrl = 'https://bigcrafters.com/finalizar-compra';
          window.oct8ne.loginUrl = 'https://bigcrafters.com/mi-cuenta';
          window.oct8ne.locale = "es-ES";
          window.oct8ne.currencyCode = "EUR";
          window.oct8ne.platform = "wordpress";
          ${
            router.asPath.includes('/producto/')
              ? `window.oct8ne.currentProduct = {
                  id: "${pageProps?.databaseId}",
                  thumbnail: "${pageProps?.image?.sourceUrl}"
                };`
              : `window.oct8ne.currentProduct = null`
          }
          window.oct8ne.customData = {
              importeCesta: null,
              categoriasProductosCesta: null,
              page: "${router.basePath}",
              productCategory: null,
              productPrice: 12,
              productSeller: null
          }
        }
        window.s = document.getElementsByTagName("script")[0];
        insertOct8ne();
        function insertOct8ne() {
          window.s.parentNode.insertBefore(oct8ne, s);
        }`}
    </Script>
  );
};

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginComponent: LoginComponent,
      loginComponentProps: {
        buttonBackgroundColor: '#f68217',
        logo: 'https://media-pre.bigcrafters.com/wp-content/uploads/2022/06/29135115/bigcrafters.png',
      },
    })
  : MyApp;
