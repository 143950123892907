import { oneLine } from 'common-tags';
import { Field } from 'formik';

import { Radio as RadioAtom } from '@/atoms';
import { InputAtom } from '@/atoms/input/Input';
import { LabelAtom } from '@/atoms/label/Label';

import type { FieldProps } from 'formik';
import type { FunctionComponent } from 'react';
import type { RadioProps } from '../types';
import type { TypeItemRadio } from './types';

/**
 * Primary UI component for user interaction
 */
export const Radio: FunctionComponent<RadioProps> = ({
  className = '',
  radioClassName = '',
  labelClass = '',
  name,
  error,
  testid,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  values,
  disabled = false,
  onClick,
}: RadioProps) => (
  <div
    className={`aria-disabled:pointer-events-none ${className}`}
    aria-disabled={disabled}
  >
    {values.map((item: TypeItemRadio) => (
      <Field name={name} value={item.value} key={item.value}>
        {({ field, form: { setFieldValue } }: FieldProps) => {
          const checked = field.value === item.value;

          return (
            <div
              className={oneLine`mb-2 flex items-start justify-start ${
                typeof radioClassName === 'function'
                  ? radioClassName(checked)
                  : (radioClassName ?? '')
              } ${
                typeof item.className === 'function'
                  ? item.className(checked)
                  : (item.className ?? '')
              } aria-disabled:pointer-events-none`}
              aria-disabled={disabled}
              onClick={(e) => {
                setFieldValue(name, item.value);
                onClick && onClick(e);
              }}
            >
              <RadioAtom
                value={item.value}
                checked={checked}
                setValue={() => setFieldValue(name, item.value)}
                name={name}
                aria-disabled={disabled}
                {...(testid && { testid })}
                className="cursor-pointer aria-disabled:pointer-events-none"
              />

              <InputAtom
                hidden
                name={name}
                type="radio"
                checked={checked}
                field={field}
                error={error}
                value={field.value}
                handleBlur={field.onBlur}
                handleChange={onChange}
                disabled={disabled}
              />

              <LabelAtom
                label={
                  typeof item.label === 'function'
                    ? item.label(checked)
                    : (item.label ?? '')
                }
                setValue={() => setFieldValue(name, item.value)}
                className={oneLine`u-body u-body--xs cursor-pointer aria-disabled:pointer-events-none ${
                  typeof labelClass === 'function'
                    ? labelClass(checked)
                    : (labelClass ?? '')
                } `}
                name={name}
                aria-disabled={disabled}
              />
            </div>
          );
        }}
      </Field>
    ))}

    {error && (
      <p className="u-label u-label--xs mt-2 text-status-error">{error}</p>
    )}
  </div>
);
