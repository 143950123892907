import { gtmPush } from './helpers';

import type { GTMProduct } from './products';

export const gtmPushMenu = ({
  label,
  menu_level,
}: {
  label: string;
  menu_level: 'first_level' | 'second_level' | 'third_level';
}): void => {
  gtmPush({
    event: 'click_menu',
    eventData: {
      label,
      menu_level,
    },
  });
};

interface GTMPushCouponCommon {
  label: string;
  location: 'header' | 'cart';
}

type GTMPushCouponConditional =
  | {
      failed?: false;
      error_message?: never;
    }
  | {
      failed: true;
      error_message: string;
    };

export const gtmPushCoupon = ({
  label,
  failed = false,
  location,
  error_message,
}: GTMPushCouponCommon & GTMPushCouponConditional): void => {
  gtmPush({
    event: failed ? 'coupon_error' : 'applied_coupon',
    eventData: {
      label,
      location,
      ...(failed && { error_message }),
    },
  });
};

export const gtmPushSelectItem = ({
  item,
  itemListName,
  index,
}: {
  item: GTMProduct;
  itemListName?: string;
  index?: number;
}) => {
  window.itemListName = itemListName?.split(' ').join('_');
  window.itemIndex = index;

  gtmPush({
    event: 'select_item',
    ecommerce: { items: [item] },
  });
};
