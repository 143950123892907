import { Listbox } from '@headlessui/react';
import { Field } from 'formik';

import SelectButton from '@/atoms/selectButton';
import SelectOption from '@/atoms/selectOption';

import type { FieldProps } from 'formik';
import type { FunctionComponent } from 'react';
import type { SelectProps } from '../types';

/**
 * Primary UI component for user interaction
 */
export const Select: FunctionComponent<SelectProps> = ({
  className = '',
  classNameSelect = '',
  reverse,
  placeholder,
  name,
  options,
  error,
  theme = 'primary' /* eslint-disable-next-line @typescript-eslint/no-empty-function */,
  onChange = () => {},
  disabled,
}: SelectProps) => (
  <div className={`relative ${className}`}>
    <Field name={name}>
      {({ field, meta, form: { setFieldValue } }: FieldProps) => {
        let lbl = placeholder;
        if (field.value) {
          const opt = options.find((item: any) => item.value == field.value);
          if (opt) {
            lbl = opt.label;
          }
        }
        return (
          <>
            <Listbox
              value={field.value}
              onChange={(e) => {
                // TODO: Añadir tipos
                const opt = options.find((item: any) => item.value == e);
                onChange(e);
                if (opt) setFieldValue(name, opt.value);
              }}
              disabled={disabled}
            >
              {({ open }) => (
                <>
                  <SelectButton
                    // TODO: Añadir tipos
                    label={field.value}
                    placeholder={lbl}
                    open={open}
                    error={error && meta.touched ? error : ''}
                    theme={theme}
                    className={classNameSelect}
                  />
                  <SelectOption
                    options={options}
                    disabled={false}
                    theme={theme}
                    reverse={reverse ?? false}
                  />
                </>
              )}
            </Listbox>
            {error && meta.touched ? (
              <p className="u-label u-label--xs mt-2 text-status-error">
                {error}
              </p>
            ) : null}
          </>
        );
      }}
    </Field>
  </div>
);
