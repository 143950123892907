import { useCallback, useEffect, useRef, useState } from 'react';

import GQLClient from '../graphql/utils';

import type { WORDPRESS_QUERY } from '../graphql/enums';

type LazyQueryOptions<TData, TVariables> = {
  variables?: TVariables;
  onCompleted?: (data: TData) => void;
  onError?: (reason: string) => void;
};

type LazyQueryResult<TData> = {
  data?: TData;
  error?: string;
  loading: boolean;
};

type LazyQueryFechResult<TData> = {
  data?: TData;
  error?: string;
};

type LazyQueryTuple<TData, TVariables> = [
  query: (
    options?: LazyQueryOptions<TData, TVariables>,
  ) => Promise<LazyQueryFechResult<TData>>,
  result: LazyQueryResult<TData>,
];

export function useLazyQuery<
  TData extends object = any,
  TVariables extends object = any,
>(
  query: WORDPRESS_QUERY,
  options?: LazyQueryOptions<TData, TVariables>,
): LazyQueryTuple<TData, TVariables> {
  const [queryResult, setQueryResult] = useState<LazyQueryResult<TData>>({
    data: undefined,
    error: undefined,
    loading: false,
  });

  const lazyQueryRef = useRef({
    query,
    options,
    queryResult,
    isMounted: false,
  });

  const execute = useCallback(async function (
    executeOptions?: LazyQueryOptions<TData, TVariables>,
  ) {
    const { queryResult, isMounted, options, query } = lazyQueryRef.current;

    if (!queryResult.loading && isMounted) {
      setQueryResult({ data: undefined, error: undefined, loading: true });

      lazyQueryRef.current.queryResult = {
        data: undefined,
        error: undefined,
        loading: true,
      };
    }

    const queryOptions = {
      query,
      variables: executeOptions?.variables || options?.variables,
      onError: executeOptions?.onError || options?.onError,
      onCompleted: executeOptions?.onCompleted || options?.onCompleted,
    };

    return GQLClient.query(queryOptions)
      .then((response) => {
        const { data, error } = response;

        if (error && queryOptions?.onError) {
          queryOptions.onError(error);
        }

        setQueryResult({ data, error, loading: false });
        lazyQueryRef.current.queryResult = { data, error, loading: false };

        if (!error && queryOptions?.onCompleted) {
          queryOptions.onCompleted(data);
        }

        return response;
      })
      .catch((error) => {
        setQueryResult({ data: undefined, error, loading: false });
        lazyQueryRef.current.queryResult = {
          data: undefined,
          error,
          loading: false,
        };

        if (queryOptions?.onError) {
          queryOptions.onError(error);
        }

        throw error;
      });
  }, []);

  useEffect(() => {
    const currentRef = lazyQueryRef.current;
    currentRef.isMounted = true;

    return () => {
      currentRef.isMounted = false;
    };
  }, []);

  useEffect(() => {
    lazyQueryRef.current.query = query;
    lazyQueryRef.current.options = options;
  });

  return [execute, queryResult];
}
