import { useCallback, useMemo } from 'react';
import { useRefinementList } from 'react-instantsearch';

import { Checkbox, Link } from '@/atoms';
import { Accordion } from '@/organisms';

import { ClearRefinements } from '@/components/algolia';
import { gtmPush, normalizeString } from '@/lib/utils';

import type { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import type { TypeRefinementListProps } from './types';

// regex to check if the label is "clean"
const RGX = new RegExp(/^.*>.*$/, 'g');

/**
 * RefinementList
 */
export const RefinementList = ({
  title,
  filtrableFieldValues,
  ...refinement
}: TypeRefinementListProps) => {
  // function to parse filters labels and return them correctly formatted
  const transformItems = useCallback(
    (items: RefinementListItem[]) => {
      // fallback in case filtrableValues is undefined or null
      if (!filtrableFieldValues) return items;

      const transformedItems: RefinementListItem[] = [];

      for (let i = 0; i < items.length; ++i) {
        const item = items[i];

        if (!item) continue;

        // we check if the label is not "clean" and contains '>'
        const label = item.label.match(RGX)
          ? (item.label.split('>').pop()?.trim() ?? item.label)
          : item.label;

        const normalizeLabel = filtrableFieldValues.find(
          (f) => f.seo === label,
        )?.title;

        transformedItems.push({
          ...item,
          // if we fail to find a name we add the algolia label
          // as fallback just to show something to the user
          label: normalizeLabel ?? label,
        });
      }

      return transformedItems;
    },
    [filtrableFieldValues],
  );

  const transformsItemsMemo = useMemo(() => transformItems, [transformItems]);

  const { items, refine, toggleShowMore, isShowingMore, canToggleShowMore } =
    useRefinementList({
      ...refinement,
      transformItems: transformsItemsMemo,
    });

  return items.length > 0 ? (
    <Accordion
      defaultOpen
      level="custom"
      accordionHeaderClass="u-headline u-headline@mobile--h3 text-typo-secondary !px-0"
      accordionBodyClass="!bg-transparent !border-0"
      items={[
        {
          question: (
            <div className="mr-2 flex w-full justify-between gap-4">
              <p>{title}</p>
              <ClearRefinements
                includedAttributes={[refinement.attribute]}
                label="quitar filtro"
              />
            </div>
          ),
          answer: (
            <div className="flex flex-col gap-2">
              {items.map((item: any, index: number) => (
                <label
                  className="items center u-label u-label--xs flex cursor-pointer gap-1 first-letter:capitalize"
                  key={index}
                  onClick={() => {
                    refine(item.value);
                    gtmPush({
                      event: 'filter_applied',
                      eventData: {
                        filter_type: normalizeString(title).replaceAll(
                          ' ',
                          '_',
                        ),
                        label: normalizeString(item.label).replaceAll(' ', '_'),
                      },
                    });
                  }}
                  {...(item.isRefined && { style: { fontWeight: 'bold' } })}
                >
                  <Checkbox className="mr-0 h-4 w-4" checked={item.isRefined} />
                  {item.label} ({item.count})
                </label>
              ))}

              {refinement?.showMore && canToggleShowMore && (
                <Link
                  size="small"
                  type="secondary"
                  className="!u-actions--chips mt-2 w-max"
                  as="button"
                  onClick={toggleShowMore}
                >
                  {isShowingMore ? 'Ver menos' : 'Ver más'}
                </Link>
              )}
            </div>
          ),
        },
      ]}
    />
  ) : null;
};

RefinementList.displayName = 'RefinementList';
