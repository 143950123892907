import { useState } from 'react';
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider';
import { useRange } from 'react-instantsearch';

import { Accordion } from '@/organisms';

import { gtmPush, normalizeString } from '@/lib/utils';

import type {
  Range,
  RangeBoundaries,
} from 'instantsearch.js/es/connectors/range/connectRange';
import type { GetHandleProps } from 'react-compound-slider';

function formatNumber(number: string | number): string {
  return Number(number).toLocaleString();
}
function Handle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  getHandleProps,
}: {
  domain: [number, number];
  handle: { id: string; value: number; percent: number };
  disabled?: boolean;
  getHandleProps: GetHandleProps;
}) {
  return (
    <>
      {/* Dummy element to make the tooltip draggable */}
      <div
        style={{
          position: 'absolute',
          left: `${percent}%`,
          width: 40,
          height: 25,
          transform: 'translate(-50%, -100%)',
          cursor: disabled ? 'not-allowed' : 'grab',
          zIndex: 1,
        }}
        aria-hidden={true}
        {...getHandleProps(id)}
      />
      <div
        role="slider"
        className="absolute z-[1] h-4 w-4 -translate-x-1/2 -translate-y-1/2 cursor-grab rounded-[50%] bg-gradient-to-t from-[#f5f5fa] to-white shadow-[0_4px_11px_0_rgba(37,44,97,0.15),0_2px_3px_0_rgba(93,100,148,0.2)] outline-none max-[899px]:h-6 max-[899px]:w-6"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          cursor: disabled ? 'not-allowed' : 'grab',
        }}
        {...getHandleProps(id)}
      />
    </>
  );
}
function convertToTicks(start: RangeBoundaries, range: Range): number[] {
  const domain =
    range.min === 0 && range.max === 0
      ? { min: undefined, max: undefined }
      : range;
  return [
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start[0] === -Infinity ? domain.min! : start[0]!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start[1] === Infinity ? domain.max! : start[1]!,
  ];
}
export function RangeSlider({
  attribute,
  min,
  max,
  title,
}: {
  attribute: string;
  min?: number;
  max?: number;
  title: string;
}) {
  const { range, start, refine, canRefine } = useRange(
    {
      attribute,
      min,
      max,
    },
    { $$widgetType: 'e-commerce.rangeSlider' },
  );
  const [ticksValues, setTicksValues] = useState(convertToTicks(start, range));
  const [prevTickValues, setPrevTickValues] = useState(ticksValues);
  const [prevStart, setPrevStart] = useState(start);
  const [touched, setTouched] = useState(false);
  const shouldShow =
    canRefine && ticksValues[0] !== undefined && ticksValues[1] !== undefined;
  if (start !== prevStart) {
    setTicksValues(convertToTicks(start, range));
    setPrevStart(start);
  }

  if (
    ticksValues[0] !== prevTickValues[0] ||
    ticksValues[1] !== prevTickValues[1]
  ) {
    setPrevTickValues(ticksValues);
  }

  const onChange = (values: readonly number[]) => {
    refine(values as [number, number]);

    // DON'T REMOVE Algolia launch this event in server side
    if (
      typeof window !== 'undefined' &&
      touched &&
      (prevTickValues[0] !== values[0] || prevTickValues[1] !== values[1])
    )
      gtmPush({
        event: 'filter_applied',
        eventData: {
          filter_type: normalizeString(attribute).replaceAll(' ', '_'),
          label: `${values[0]}-${values[1]}`,
        },
      });
  };
  const onUpdate = (values: readonly number[]) => {
    setTouched(true);
    setTicksValues(values as [number, number]);
  };
  return shouldShow ? (
    <Accordion
      defaultOpen
      level="custom"
      accordionHeaderClass="u-headline u-headline@mobile--h3 text-typo-secondary !px-0"
      accordionBodyClass="!bg-transparent !border-0 bg-primary-400"
      items={[
        {
          question: (
            <p className="mr-2 flex w-full justify-between gap-4">
              {`${title} (${ticksValues.at(0)} € - ${ticksValues.at(-1)} €)`}
            </p>
          ),
          answer: (
            <div className="flex flex-col gap-2 pl-2">
              <Slider
                mode={2}
                step={1}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                domain={[range.min!, range.max!]}
                values={start as number[]}
                disabled={!canRefine}
                onChange={onChange}
                onUpdate={onUpdate}
                rootStyle={{ position: 'relative', marginTop: '1.5rem' }}
                className="ais-RangeSlider"
              >
                <Rail>
                  {({ getRailProps }) => (
                    <div
                      className="absolute h-[3px] w-full cursor-pointer rounded bg-[#4142470E]"
                      {...getRailProps()}
                    />
                  )}
                </Rail>
                <Tracks left={false} right={false}>
                  {({ tracks, getTrackProps }) => (
                    <div>
                      {tracks.map(({ id, source, target }) => (
                        <div
                          key={id}
                          className="absolute h-[3px] cursor-pointer rounded-[3px] bg-secondary-500"
                          style={{
                            left: `${source.percent}%`,
                            width: `${target.percent - source.percent}%`,
                          }}
                          {...getTrackProps()}
                        />
                      ))}
                    </div>
                  )}
                </Tracks>
                <Handles>
                  {({ handles, getHandleProps }) => (
                    <div>
                      {handles.map((handle) => (
                        <Handle
                          key={handle.id}
                          handle={handle}
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          domain={[min!, max!]}
                          getHandleProps={getHandleProps}
                        />
                      ))}
                    </div>
                  )}
                </Handles>
                <Ticks values={ticksValues}>
                  {({ ticks }) => (
                    <div>
                      {ticks.map(
                        ({ id, value, percent }) =>
                          (value || value === 0) && (
                            <div
                              key={id}
                              className="absolute -top-7 flex -translate-x-1/2 cursor-grab select-none text-center text-xs font-bold"
                              style={{
                                left: `${percent}%`,
                              }}
                            >
                              {formatNumber(value)}
                              <span> €</span>
                            </div>
                          ),
                      )}
                    </div>
                  )}
                </Ticks>
              </Slider>
            </div>
          ),
        },
      ]}
    />
  ) : null;
}
