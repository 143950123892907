import { useEffect } from 'react';
import { useRouter } from 'next/router';

/**
 * Hook to execute a callback before history change.
 * @param  {() => void} callback Function that will be executed when route is started.
 */
export const useBeforeHistoryChange = (callback: () => void) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('beforeHistoryChange', callback);

    return () => router.events.off('beforeHistoryChange', callback);
  }, [callback, router]);
};
