import { useSession } from 'next-auth/react';
import dynamic from 'next/dynamic';

import { FlexibleContent, HeroSlider } from '@/templates';
import { Category } from '@/pages';
import { getBaseLayout } from '@/layouts/BaseLayout';

import { getAllRoutes, getInitialProps } from '@/lib/graphql/api';
import { getMergedPages } from '@/lib/graphql/api/getMergedPages';
import {
  filtrableFields,
  FlexibleContentBlocks,
  FlexibleContentClone,
  pageAgeGate,
  pageHeaderFooter,
  pagePropsPartial,
  pageTheme,
  seo,
  sliderHero,
} from '@/lib/graphql/partials';
import { PageQuery } from '@/lib/graphql/querys';
import { getWordpressPostType } from '@/lib/graphql/server';
import { pagePropsServerTransformer } from '@/lib/graphql/transformers';
import {
  getAppliedFiltersToURL,
  getCathAllSegments,
  getPagePropsForAppliedFiltersToURL,
  getPathsFromIndex,
  getRedirect,
} from '@/lib/utils';

import type { TypeCategoryProps } from '@/pages/category';
import type { TypePageProps } from '@/pages/page';
import type {
  GetStaticPaths,
  GetStaticProps,
  InferGetStaticPropsType,
} from 'next';
import type { NextPageWithLayout } from './_app';

const Page = dynamic(() => import('@/pages/page'));

type Page = {
  type: 'Page';
  page: {
    hero: TypePageProps['hero'];
    flexibleContent: {
      flexible: TypePageProps['flexible'];
    };
  };
};

type ProductCategory = {
  type: 'ProductCategory';
  pageProps: {
    hero: any;
    flexibleContent: {
      flexible: any[];
    };
    isAttribute: boolean;
    showDefaultCategory: boolean;
    [key: string]: any;
  };
  productCategory: TypeCategoryProps;
};

type TypeServerSidePropsContext = {
  slug: string[];
};

type TypePage = Page | ProductCategory;

const ProductCategory = ({ pageProps, productCategory }: ProductCategory) => {
  const session: any = useSession();

  return (
    <>
      <HeroSlider hero={pageProps.hero} />

      <div data-pagetype="category" data-pagename={pageProps.pageName} />

      {(pageProps.isAttribute || pageProps.showDefaultCategory) && (
        <Category
          userId={session?.data?.user?.UID}
          {...productCategory}
          showTitleAsH1
        />
      )}

      <FlexibleContent
        type="ProductCategory"
        flexible={pageProps.flexibleContent.flexible}
      />
    </>
  );
};

const CategoryPage: NextPageWithLayout<
  InferGetStaticPropsType<typeof getStaticProps>
> = (props: InferGetStaticPropsType<typeof getStaticProps>) =>
  props.type === 'ProductCategory' ? (
    <ProductCategory {...props} />
  ) : (
    props.type === 'Page' && (
      <Page
        hero={props.page.hero}
        flexible={props.page.flexibleContent?.flexible}
      />
    )
  );

export const getStaticProps: GetStaticProps<
  TypePage,
  TypeServerSidePropsContext
> = async ({ params }) => {
  const realUrl = params?.slug?.join('/') || '/';

  const { hasRedirect, redirect } = await getRedirect(params?.slug);

  if (hasRedirect && process.env.NEXT_REDIRECTS_SERVER_ISR_ENABLED === 'true') {
    return { redirect };
  }

  const {
    filterSlug,
    filterValue,
    algoliaFilter,
    wordpressSlug,
    url,
    arrayOfCategories,
  } = await getAppliedFiltersToURL(params?.slug ?? []);

  const wordpressPostType = await getWordpressPostType({
    categorySlug: wordpressSlug || '/',
    pagePath: url || '/',
  });

  if (!wordpressPostType) return { notFound: true };

  if (wordpressPostType === 'Page') {
    const pageQuery = `
      page(id: $slug, idType: URI) {
        flexibleContent: ${FlexibleContentBlocks('Page')}
        hero: ${sliderHero('Page')}
      }
    `;

    const query = PageQuery(pagePropsPartial, pageQuery);
    const initialProps = await getInitialProps(query, {
      slug: realUrl || '',
    });

    return await pagePropsServerTransformer({
      staticProps: initialProps,
      type: 'Page',
      path: realUrl || '',
    });
  }

  const Query = PageQuery(
    `
    productCategory(id: $slug, idType: SLUG) {
      ${filtrableFields}
      ${pageAgeGate}
      ${pageHeaderFooter}
      ${pageTheme}
      id
      name
      slug
      ${seo}
      flexibleContent: ${FlexibleContentBlocks('ProductCategory')}
      hero: ${sliderHero('ProductCategory')}
      customSeo(filterSlug: $filterSlug, filterValue: $filterValue) {
        titleSeo
        descriptionSeo
        customFields {
          miraklfilterSeo {
            descriptionSeo
            titleSeo
             flexibleContent: ${FlexibleContentClone(
               'FilterSEO',
               'Miraklfilterseo',
             )}
          }
        }
      }
      taxonomyCategoriaProducto {
        disableListProducts
      }
    }
  `,
    '',
    'Category',
  );

  const initialProps = await getInitialProps(Query, {
    slug: wordpressSlug,
    filterSlug,
    filterValue,
  });

  // Retornamos los valores iniciales de Wordpress cuando no se encuentra la página
  // para evitar llamadas innecesarias a la API de Algolia
  if (initialProps.notFound) {
    return initialProps;
  }

  initialProps.props.isAttribute = Boolean(filterSlug) || Boolean(filterValue);

  initialProps.props.showDefaultCategory =
    !initialProps.props.pageProps.taxonomyCategoriaProducto.disableListProducts;

  initialProps.props.pageProps = getPagePropsForAppliedFiltersToURL(
    initialProps.props.pageProps,
    url,
  );

  initialProps.props.arrayOfCategories = arrayOfCategories;
  initialProps.props.wordpressSlug = wordpressSlug;
  initialProps.props.algoliaFilter = algoliaFilter;
  initialProps.props.slug = params?.slug;

  return await pagePropsServerTransformer({
    staticProps: initialProps,
    type: wordpressPostType,
    path: realUrl ?? url,
  });
};

export const getStaticPaths = (async () => {
  const mergedPagesToBuild = await getMergedPages();
  const paths = process.env.FULL_DEPLOY
    ? await getAllRoutes({ type: 'productCategories' })
    : await getPathsFromIndex('categories');

  return {
    paths: getCathAllSegments({
      paths: paths.concat(mergedPagesToBuild),
      segmentName: 'slug',
      base: 'categoria-producto',
      catchAll: true,
    }),
    fallback: 'blocking',
  };
}) satisfies GetStaticPaths;

CategoryPage.getLayout = getBaseLayout;

export default CategoryPage;
