export const provincias = [
  { label: 'A Coruña', value: 'C' },
  { label: 'Albacete', value: 'AB' },
  { label: 'Alacant/Alicante', value: 'A' },
  { label: 'Almería', value: 'AL' },
  { label: 'Araba/Álava', value: 'VI' },
  { label: 'Asturias', value: 'O' },
  { label: 'Ávila', value: 'AV' },
  { label: 'Badajoz', value: 'BA' },
  { label: 'Barcelona', value: 'B' },
  { label: 'Bizkaia', value: 'BI' },
  { label: 'Burgos', value: 'BU' },
  { label: 'Cáceres', value: 'CC' },
  { label: 'Cádiz', value: 'CA' },
  { label: 'Cantabria', value: 'S' },
  { label: 'Castelló/Castellón', value: 'CS' },
  { label: 'Ciudad Real', value: 'CR' },
  { label: 'Córdoba', value: 'CO' },
  { label: 'Cuenca', value: 'CU' },
  { label: 'Gipuzkoa', value: 'SS' },
  { label: 'Girona', value: 'GI' },
  { label: 'Granada', value: 'GR' },
  { label: 'Guadalajara', value: 'GU' },
  { label: 'Huelva', value: 'H' },
  { label: 'Huesca', value: 'HU' },
  { label: 'Jaén', value: 'J' },
  { label: 'La Rioja', value: 'LO' },
  { label: 'León', value: 'LE' },
  { label: 'LLeida', value: 'L' },
  { label: 'Lugo', value: 'LU' },
  { label: 'Madrid', value: 'M' },
  { label: 'Málaga', value: 'MA' },
  { label: 'Murcia', value: 'MU' },
  { label: 'Navarra', value: 'NA' },
  { label: 'Ourense', value: 'OR' },
  { label: 'Palencia', value: 'P' },
  { label: 'Pontevedra', value: 'PO' },
  { label: 'Salamanca', value: 'SA' },
  { label: 'Segovia', value: 'SG' },
  { label: 'Sevilla', value: 'SE' },
  { label: 'Soria', value: 'SO' },
  { label: 'Tarragona', value: 'T' },
  { label: 'Teruel', value: 'TE' },
  { label: 'Toledo', value: 'TO' },
  { label: 'València/Valencia', value: 'V' },
  { label: 'Valladolid', value: 'VA' },
  { label: 'Zamora', value: 'ZA' },
  { label: 'Zaragoza', value: 'Z' },
];

export function getProvinceLabelFromValue(value: string) {
  const Label = provincias.find(
    (provincia) => provincia.value == value && provincia.label,
  );
  return Label?.label;
}

export function getProvinceValueFromLabel(label: any) {
  const Value = provincias.find(
    (provincia) => provincia.label == label && provincia.value,
  );
  return Value ? Value.value : '';
}
