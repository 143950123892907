import type { FunctionComponent } from 'react';
import type { LabelProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const LabelAtom: FunctionComponent<LabelProps> = ({
  name,
  className = '',
  setValue,
  events = true,
  label,
  testid,
  ...props
}: LabelProps) => (
  <label
    htmlFor={name}
    onClick={setValue}
    className={`${!events ? 'pointer-events-none' : 'pointer-events-auto'} ${className} `}
    {...(testid && { 'data-playwright': `lbl_${testid}` })}
    {...props}
  >
    {label}
  </label>
);
