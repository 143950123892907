import { useRef, useState } from 'react';

import { DEFAULT_TOAST_OPTIONS_INFO } from '@/components/providers';
import { useCartDispatch } from './useCartDispatch';
import { useToastDispatch } from './useToastDispatch';

import type { TypeCartDispatch } from '@/components/providers/CartProvider';
import type { TypeState } from '@/molecules/cart-icon';
import type { useHits } from 'react-instantsearch';

export const useAddToCart = (config?: {
  onAdd?: () => void;
  hit?: ReturnType<typeof useHits>['hits'][number];
  sendEvent?: ReturnType<typeof useHits>['sendEvent'];
}): { state: TypeState; add: TypeCartDispatch['add'] } => {
  const { onAdd, hit, sendEvent } = config ?? {};
  const [state, setState] = useState<TypeState>('DEFAULT');
  const { add } = useCartDispatch();
  const { toast } = useToastDispatch();
  const timeOut = useRef<NodeJS.Timeout>();

  const addProduct: TypeCartDispatch['add'] = async ({
    productId,
    quantity,
    extraData,
    gtm,
  }) => {
    setState('LOADING');

    if (timeOut?.current) clearTimeout(timeOut.current);

    await add({ productId, quantity, extraData, gtm })
      .then(() => {
        setState('ACTIVE');
        onAdd && onAdd();
        toast('Producto añadido a la cesta.', DEFAULT_TOAST_OPTIONS_INFO);
        sendEvent &&
          hit &&
          sendEvent('conversion', hit, 'Product Added to Cart');
      })
      .catch(() => setState('ERROR'));

    timeOut.current = setTimeout(() => setState('DEFAULT'), 1500);
  };

  return { state, add: addProduct };
};
