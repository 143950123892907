export const enum WORDPRESS_QUERY {
  // Cart
  GET_CART,

  // Employee Snakbar
  GET_EMPLOYEE_SNACKBAR,
  GET_EMPOYEE_CUPON_LEFT_USES,

  // Other
  OFFENSIVE_WORDS,
  INCIDENCE_REASONS,
  EXPERIENCIES,
  PAYMENT_METHODS,
  GET_CUSTOMER_DIRECTIONS,
  PAYMENT_FORM,
  TRIBU,
  USER_VOTES,
  USER_DIRECTIONS,

  // Orders
  ORDER_RESUME,
  SUBSCRIPTIONS,
  GET_GUEST_ORDER,
  ORDER, // Simple order, used only in the SearchOrder component
  PREVIEW_ORDERS,
}

export const enum WORDPRESS_MUTATION {
  // Cart
  ADD_TO_CART,
  UPDATE_QUANTITY,
  APPLY_COUPON,
  REMOVE_COUPON,
  UPDATE_SUBSCRIPTION,
  CLEAN_CART,
  REPEAT_ORDER,

  // Other
  VOTE_SELLER,
  CHECK_ORDER_AS_RECIVED,
  VERIFY_PRODUCT_ALERT,
  CHECKOUT,
  MIRAKL_CANCEL_ORDER,
  OPEN_INCIDENCE,
  UPDATE_CUSTOMER_INFO,
  SEND_ERROR_LOG,

  // Paymenth methods
  ADD_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,

  // Customer directions
  DELETE_CUSTOMER_DIRECTION,
  UPDATE_CUSTOMER_DIRECTION,
  UPDATE_CUSTOMER_DEFAULT_DIRECTION,
}
