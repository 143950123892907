import { useContext, useState } from 'react';
import Image from 'next/future/image';
import { oneLine } from 'common-tags';

import { ConditionalWrapper } from '@/hoc';
import { NextLink } from '@/atoms';

import { InitPropsProvider } from '@/components/providers';
import { useRouteChangeStart } from '@/lib/hooks';
import { gtmPushMenu } from '@/lib/utils';
import Column from './Column';

/**
 * DesktopMenu
 */
export const DesktopMenu = () => {
  const [open, setOpen] = useState(-1);

  let timeOut: NodeJS.Timeout;

  const {
    menus: { primaryMenu },
  } = useContext(InitPropsProvider);

  useRouteChangeStart(() => setOpen(-1));

  return (
    <div className="max-lg:hidden">
      <div
        className={`fixed inset-0 -z-10 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity ${open >= 0 ? 'opacity-100' : 'pointer-events-none opacity-0'}`}
        aria-hidden="true"
      />

      <div aria-label="Top" className="u-wrapper !static z-50 hidden lg:block">
        <nav
          className="flex w-full items-start justify-between gap-6 max-lg:hidden"
          onMouseLeave={() => setOpen(-1)}
        >
          {primaryMenu?.map((category, index) => (
            <div
              key={index}
              className="flex items-center justify-center"
              onMouseEnter={() => {
                if (!category.numColumns) setOpen(-1);

                if (open !== -1) {
                  if (category.numColumns) setOpen(index);
                  return;
                }

                timeOut = setTimeout(() => {
                  if (category.numColumns) setOpen(index);
                }, 400);
              }}
              onMouseLeave={() => {
                clearTimeout(timeOut);
              }}
            >
              <div
                className="u-actions u-actions--nav relative z-10 flex items-center"
                onClick={() =>
                  gtmPushMenu({
                    menu_level: 'first_level',
                    label: category.name,
                  })
                }
              >
                <ConditionalWrapper
                  condition={Boolean(category.href)}
                  wrapper={(children) => (
                    <NextLink href={category.href ?? ''}>
                      <a>{children}</a>
                    </NextLink>
                  )}
                >
                  {category.name}
                </ConditionalWrapper>
              </div>

              {category.numColumns && (
                <div
                  // prettier-ignore
                  className={oneLine`
                    absolute inset-x-0 top-[calc(100%-64px)] w-full transition-opacity
                    ${
                      open === index
                      ? 'opacity-100'
                      : 'pointer-events-none opacity-0'
                    }
                  `}
                >
                  <div className="pt-16 text-sm text-gray-500">
                    {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                    <div
                      className="absolute inset-0 top-1/2 bg-primary-50 shadow"
                      aria-hidden="true"
                    />

                    <div className="relative z-50 bg-primary-50">
                      <div className="u-wrapper grid grid-flow-col gap-x-8 gap-y-10 border-t border-primary-100 px-4 py-5">
                        {/* FEATURED */}
                        {category.image ? (
                          <div
                            className="col-start-2 grid max-w-[250px] grid-flow-col gap-x-8"
                            onClick={() =>
                              gtmPushMenu({
                                menu_level: 'second_level',
                                label:
                                  category.image?.alt || 'Imagen destacada',
                              })
                            }
                          >
                            <ConditionalWrapper
                              condition={Boolean(category.imageLink)}
                              wrapper={(children) => (
                                <NextLink href={category.imageLink ?? ''}>
                                  <a
                                    aria-label={
                                      category.image?.alt || 'Imagen destacada'
                                    }
                                  >
                                    {children}
                                  </a>
                                </NextLink>
                              )}
                            >
                              <Image
                                src={category.image.src}
                                width={category.image.width}
                                height={category.image.height}
                                alt={category.image?.alt ?? 'Imagen destacada'}
                              />
                            </ConditionalWrapper>
                          </div>
                        ) : null}

                        {/* COLUMNS */}
                        <div className="row-start-1 grid grid-flow-col gap-x-8 gap-y-10 pr-[150px] text-sm">
                          {category.columns?.map((column, columnIndex) => (
                            <Column
                              columns={column}
                              key={columnIndex}
                              parentMenuName={category.name}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};
