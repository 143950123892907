import { array, number, object, string } from 'yup';

import {
  DirectionApartmentSchema,
  DirectionCitySchema,
  DirectionPhoneSchema,
  DirectionPostCodeSchema,
  DirectionProvinceSchema,
  DirectionStreetSchema,
  FORMS_ERROS,
  maxText,
  minText,
} from '@/lib/utils';

import type { SessionContextValue } from 'next-auth/react';
import type { AnyObject, StringSchema } from 'yup';

export const FORM_INITIAL_VALUES = {
  isGift: [] as string[],
  giftName: '',
  giftApellidos: '',
  giftStreet: '',
  giftCity: '',
  giftApartment: '',
  giftProvince: '',
  giftCp: '',
  giftPhone: '',
  giftEmail: '',
  giftNota: '',

  // We use this value for the standard direction when the order is not a gitf
  // as well for the buyer direction when the order is a gift.
  direction: -1,

  // This values are used when the user is not logged in. Works with both gift and standard orders.
  shippingStreet: '',
  shippingCity: '',
  shippingApartment: '',
  shippingProvince: '',
  shippingPhone: '',
  shippingPostCode: '',
};

export type CheckoutDirectionFormValues = typeof FORM_INITIAL_VALUES;

const validateGiftField = (
  then: (
    schema: StringSchema<string | undefined, AnyObject, undefined, ''>,
  ) => StringSchema<string | undefined, AnyObject, undefined, ''>,
) =>
  string().when('isGift', {
    is: (data: string[]) => data.length !== 0,
    then,
  });

const validateShippingField = (
  session: SessionContextValue,
  then: (
    schema: StringSchema<string | undefined, AnyObject, undefined, ''>,
  ) => StringSchema<string | undefined, AnyObject, undefined, ''>,
) =>
  string().when([], {
    is: () => session.status === 'unauthenticated',
    then,
  });

export const FormSchema = (session: SessionContextValue) =>
  object().shape({
    // ~~~~~~~~~~ Direction fields schema ~~~~~~~~~~ //

    direction: number().moreThan(-1, 'Elige una dirección'),

    // ~~~~~~~~~~ Shipping fields schema ~~~~~~~~~~ //

    shippingStreet: validateShippingField(session, DirectionStreetSchema),
    shippingApartment: validateShippingField(session, DirectionApartmentSchema),
    shippingCity: validateShippingField(session, DirectionCitySchema),
    shippingPostCode: validateShippingField(session, DirectionPostCodeSchema),
    shippingPhone: validateShippingField(session, DirectionPhoneSchema),
    shippingProvince: validateShippingField(session, DirectionProvinceSchema),

    // ~~~~~~~~~~ Gift fields schema ~~~~~~~~~~ //

    isGift: array().of(string()),
    giftStreet: validateGiftField((schema) =>
      schema
        .min(1, minText(1))
        .max(30, maxText(30))
        .required(FORMS_ERROS.required),
    ),
    giftName: validateGiftField((schema) =>
      schema
        .min(1, minText(1))
        .max(30, maxText(30))
        .required(FORMS_ERROS.required),
    ),
    giftApellidos: validateGiftField((schema) =>
      schema
        .min(1, minText(1))
        .max(30, maxText(30))
        .required(FORMS_ERROS.required),
    ),
    giftApartment: validateGiftField((schema) => schema.max(30, maxText(30))),
    giftProvince: validateGiftField((schema) =>
      schema.required(FORMS_ERROS.required),
    ),
    giftCity: validateGiftField((schema) =>
      schema
        .min(1, minText(1))
        .max(30, maxText(30))
        .required(FORMS_ERROS.required),
    ),
    giftEmail: validateGiftField((schema) =>
      schema.email('El email no es válido').required(FORMS_ERROS.required),
    ),
    giftCp: validateGiftField((schema) =>
      schema
        .matches(/^\d{5}$/, {
          message: 'Introduce un código postal válido.',
          excludeEmptyString: false,
        })
        .required(FORMS_ERROS.required),
    ),
    giftPhone: validateGiftField((schema) =>
      schema
        .matches(/^[6-9]\d{8}$/, {
          message: 'Introduce un número válido.',
          excludeEmptyString: false,
        })
        .required(FORMS_ERROS.required),
    ),
    giftNota: validateGiftField((schema) =>
      schema.required(FORMS_ERROS.required),
    ),
  });
