import { getSession } from 'next-auth/react';

import { debug, isOperator } from '@/lib/utils';

import type { TypeProductCardWordpressGraphQL } from '@/lib/graphql/transformers/card-product/woordpress';
import type { TypeCart } from '@/lib/graphql/types';
import type { GTMProduct } from './products';

export const AFFILIATION_NAME_OPERATOR = 'Hijos de Rivera' as const;

export const debugGtm = (debugMsg: string, data: any): void => {
  const isProd: boolean = process.env.NODE_ENV === 'production';

  // eslint-disable-next-line no-console
  if (!isProd) console.log(`${debugMsg}: `, data);
};

export const getPageType = (): GTMPageType => {
  const path = window.location.pathname;
  const pageType = (document.querySelector('[data-pagetype]') as HTMLElement)
    ?.dataset.pagetype;

  if (path === '/') return 'home';
  if (path.includes('/finalizar-compra') || path.includes('/cesta'))
    return 'checkout';
  if (pageType === 'category') return 'category';
  if (path.includes('/producto/')) return 'product';
  if (path.includes('/mi-cuenta')) return 'account';
  if (path.includes('/blog')) return 'blog';

  return 'other';
};

export const getPageAffiliation = () => {
  const seller = document.querySelector(
    '.cn_product_visited .tag',
  )?.textContent;

  return seller
    ? isOperator(seller)
      ? AFFILIATION_NAME_OPERATOR
      : seller
    : 'other';
};

export const getPageName = () => {
  const path = window.location.pathname;
  const query = window.location.search;
  const pageName = (document.querySelector('[data-pagename]') as HTMLElement)
    ?.dataset.pagename;
  const isCategory = (document.querySelector('[data-pagetype]') as HTMLElement)
    ?.dataset.pagename;
  const cleanName = (text: string | undefined | null) =>
    text ? normalizeString(text).replaceAll(' ', '_') : undefined;

  if (path === '/') return '/home';
  if (isCategory === 'category')
    return `/category${pageName || cleanName(path)}`;
  if (path.includes('/buscador?')) return `/buscador/${query}`;
  if (path.includes('/marca/')) return `/marca/${pageName}`;
  if (path.includes('/vendedores/')) return `/vendedores/${pageName}`;
  if (path.includes('/producto/caja-de-vinos-personalizada'))
    return '/producto/personaliza-tu-caja-de-vinos';
  if (path.includes('/producto/')) return `/producto/${pageName}`;
  if (path === '/finalizar-compra') return '/checkout/login';
  if (path.includes('/finalizar-compra/paso/datos'))
    return '/checkout/datos_personales';
  if (path.includes('/finalizar-compra/paso/direccion'))
    return '/checkout/direccion';
  if (path.includes('/finalizar-compra/paso/confirmacion'))
    return '/checkout/pago';
  if (path.includes('/finalizar-compra/paso/gracias'))
    return '/checkout/confirmacion';
  if (path.includes('/blog/')) return `/blog/${pageName}`;
  if (path === '/mi-cuenta') return '/my-account';
  if (path.includes('/mi-cuenta/editar-cuenta'))
    return '/my-account/edit-account';
  if (path.includes('/mi-cuenta/cambiar-contrasena'))
    return '/my-account/change-password';
  if (path.includes('/mi-cuenta/mis-direcciones'))
    return '/my-account/my-addresses';
  if (path.includes('/mi-cuenta/formas-de-pago'))
    return 'my-account/payment-methods';
  if (path.includes('/mi-cuenta/mis-compras'))
    return '/my-account/my-purchases';
  if (path.includes('/mi-cuenta/mis-experiencias'))
    return '/my-account/my-experiences';
  if (path.includes('/mi-cuenta/mis-suscripciones'))
    return '/my-account/my-subscriptions';
  if (path.includes('/mi-cuenta/contacto')) return '/my-account/contact';

  return path;
};

export const getLocationName = () => {
  const path = window.location.pathname;
  const isCategory = (document.querySelector('[data-pagetype]') as HTMLElement)
    ?.dataset.pagename;

  if (path === '/') return 'home';
  if (isCategory === 'category') return 'categoria';
  if (path.includes('/buscador?')) return 'buscador';
  if (path.includes('/marca/')) return 'categoria';
  if (path.includes('/vendedores/')) return 'categoria';
  if (path.includes('/producto/')) return 'producto';
  if (path.includes('/cesta')) return 'cart';
  if (path.includes('/blog')) return 'blog';

  return 'categoria';
};

type GTMPageType =
  | 'home'
  | 'checkout'
  | 'product'
  | 'category'
  | 'account'
  | 'blog'
  | 'other';

interface GTMData {
  /**
   * The name of the event
   */
  event: string;
  /**
   * The context of the page
   */
  context?: {
    pagename: string;
    user_status: 'logged' | 'unlogged';
    page_affiliation: string;
    page_type: GTMPageType;
  };
  /**
   * The user data
   */
  user?: {
    im_user_id: number | undefined;
    im_email_sha256: string | undefined;
    im_email_connectif: string | undefined;
    im_user_type: 'new' | 'returning' | undefined;
  };
  /**
   * The event data
   */
  eventData?: {
    label?: string;
    menu_level?: 'first_level' | 'second_level' | 'third_level';
    location?: string;
    method?: string;
    error_type?: 'form' | 'server';
    error_message?: string;
    content_type?: string;
    search_term?: string;
    filter_type?: string;
    im_error_message?: string;
    im_value?: number;
    im_transaction_id?: string;
    im_currency?: 'EUR';
  };
  /**
   * The ecommerce data
   */
  ecommerce?: {
    currency?: 'EUR';
    value?: number;
    coupon?: string;
    transaction_id?: string;
    shipping?: number;
    tax?: number;
    location?: string;
    payment_type?: string;
    im_payment_type?: string;
    ecom_value?: number;
    discount_purchase?: number;
    purchase_type?: 'unique' | 'recurrent';
    items: GTMProduct[];
  };
}

export const gtmPushQueuedEvents = () => {
  const gtmQueue = JSON.parse(sessionStorage.getItem('gtmQueue') || '[]');

  gtmQueue.forEach((el: GTMData) => {
    // Reset ecommerce object
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.dataLayer?.push({ ecommerce: null, eventData: null });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.dataLayer?.push(el);
  });

  sessionStorage.removeItem('gtmQueue');
  sessionStorage.removeItem('gtmQueueEnable');
};

export const gtmPush = async ({
  event,
  context,
  ecommerce,
  eventData,
  user,
}: Omit<GTMData, 'user'> & { user?: boolean }): Promise<void> => {
  let userData: GTMData['user'] = undefined;

  if (user) {
    const { user: userSession } = (await getSession()) || {};
    const customerHasPurchased = userSession?.email
      ? await customerHasBought(userSession.email)
      : false;

    userData = {
      im_user_id: (userSession as any)?.UID || undefined,
      im_email_sha256: (userSession as any)?.emailSHA256 || undefined,
      im_email_connectif: (userSession as any)?.emailEncripted || undefined,
      im_user_type: customerHasPurchased
        ? 'returning'
        : userSession
          ? 'new'
          : undefined,
    };
  }

  // Nos aseguramos de mandar todos los campos con valores o undefined
  const gtm: GTMData = {
    event,
    ...(context && { context }),
    ...(user && { user: userData }),
    ...(eventData && { eventData }),
    ...(ecommerce && { ecommerce }),
  };

  const pageViewFired: boolean =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.dataLayer?.some((el: any) => el?.event === 'page_view') || false;
  const gtmQueueEnabled = sessionStorage.getItem('gtmQueueEnable') === 'true';

  if ((!pageViewFired || gtmQueueEnabled) && gtm.event !== 'page_view') {
    const gtmQueue = JSON.parse(sessionStorage.getItem('gtmQueue') || '[]');
    gtmQueue.push(gtm);
    sessionStorage.setItem('gtmQueue', JSON.stringify(gtmQueue));
    return;
  }

  // Reset ecommerce object
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.dataLayer?.push({ ecommerce: null, eventData: null });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.dataLayer?.push(gtm);

  if (gtm.event === 'page_view') gtmPushQueuedEvents();

  debugGtm('GTM PUSH', gtm);
};

export const getProductCategoriesTree = (product?: {
  productCategoryForSeo?:
    | TypeProductCardWordpressGraphQL['productCategoryForSeo']
    | null;
  [key: string]: unknown;
}) => {
  const categoiesTree: {
    [key: string]: string;
  } = {};

  product?.productCategoryForSeo?.forEach((el, index) => {
    const categoryLabel =
      index === 0 ? 'item_category' : `item_category${index + 1}`;

    if (el.name) categoiesTree[categoryLabel] = el.name;
  });

  return categoiesTree;
};

export const updateCartEvent = (
  loadedCartCount: number,
  responseCartCount: number,
): string =>
  loadedCartCount < responseCartCount ? 'add_to_cart' : 'remove_from_cart';

export const getAddedOrUpdatedItemFromCart = (
  productKey: string,
  cartContent: TypeCart['cart'],
) => {
  const cartItems = cartContent.contents.products;
  const itemIndex = cartItems.findIndex((el: any) => el.key === productKey);

  return { data: cartItems[itemIndex], itemIndex };
};

export const customerHasBought = async (
  customerEmail: string,
): Promise<null | boolean> => {
  let hasBought = null;

  await fetch(`${process.env.NEXT_PUBLIC_FRONT_URL}/api/customerhasbought`, {
    method: 'POST',
    body: JSON.stringify({ email: customerEmail }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      hasBought = res.message ? true : false;
    })
    .catch((err) => debug(err));

  return hasBought;
};

export async function sha256(message: string) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array

  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
}

/**
 * Remove diacritics from a string
 * @see https://caniuse.com/mdn-javascript_regular_expressions_unicode_character_class_escape
 * @see https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Regular_expressions/Unicode_character_class_escape
 *
 * @param {string} str String to remove diacritics
 * @returns {string} String without diacritics
 *
 * @example
 * removeDiacritics('áéíóú') // 'aeiou'
 */
const removeDiacritics = (str: string): string =>
  str.normalize('NFD').replace(/\p{Diacritic}/gu, '');

/**
 * Remove special characters from a string
 * @param {string} str String to remove special characters
 * @returns {string} String without special characters
 *
 * @example
 * removeSpecialChars('%Álvaro logroño%') // 'Álvarologroño'
 */
const removeSpecialChars = (str: string): string =>
  str.replace(/[^a-zA-Z0-9 ]/g, '');

/**
 * Normalize a string for GTM
 * @param {string} str String to normalize for GTM
 * @returns {string} String normalized for GTM
 *
 * @example
 * normalizeString('%Álvaro logroño%') // 'alvaro logrono'
 */
export const normalizeString = (str: string = ''): string =>
  removeSpecialChars(removeDiacritics(str.toLocaleLowerCase()));

/**
 * Clean the GTM item data (itemListName and itemIndex)
 */
export const cleanGTMItemData = () => {
  window.itemListName = undefined;
  window.itemIndex = undefined;
};
