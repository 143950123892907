import { getProvinceValueFromLabel } from '@/lib/utils/provincias';

export const transformProviderToWp = (data: any) => ({
  shipping: {
    address1: data.shipping_street,
    address2: data.shipping_apartment,
    state:
      getProvinceValueFromLabel(data.shipping_province) !== ''
        ? getProvinceValueFromLabel(data.shipping_province)
        : data.shipping_province,
    city: data.shipping_city,
    phone: data.shipping_phone,
    postcode: data.shipping_cp,
    firstName: data.firstName,
    lastName: data.lastName,
    country: 'ES',
  },
  billing: {
    email: data.email ?? data?.user?.email,
    address1: data.billing_street,
    address2: data.billing_apartment,
    state:
      getProvinceValueFromLabel(data.billing_province) !== ''
        ? getProvinceValueFromLabel(data.billing_province)
        : data.billing_province,
    city: data.billing_city,
    phone: data.billing_phone,
    postcode: data.billing_cp,
    firstName: data.firstName,
    lastName: data.lastName,
    country: 'ES',
  },
  customerNote: data.notas ? data.notas : '',
  paymentMethod:
    data.paymentMethod == 'Tarjeta'
      ? 'redsys'
      : data.paymentMethod == 'Paypal'
        ? 'ppcp-gateway'
        : data.paymentMethod === 'Bizum'
          ? 'bizumredsys'
          : data.paymentMethod,
  shipToDifferentAddress: true,
});

export const enum CHECKOUT_STEPS {
  PERSONAL_DATA = 0,
  SHIPPING_ADDRESS = 1,
  CONFIRMATION = 2,
}
