import { object, string } from 'yup';

import APClient from '@/lib/graphql/utils';
import {
  getProvinceLabelFromValue,
  getProvinceValueFromLabel,
  provincias,
} from '@/lib/utils/provincias';
import { WORDPRESS_MUTATION } from '../graphql/enums';

import type { InferType, StringSchema } from 'yup';

export const FORMS_ERROS = {
  required: 'El campo es obligatorio',
  min2Chars: 'Mínimo 2 carácteres',
  noValidChars: 'Caracteres no válidos',
};

export const minText = (min: number) =>
  `El campo debe tener como mínimo ${min} carácteres`;
export const maxText = (max: number) =>
  `El campo debe tener como máximo ${max} carácteres`;

export const DirectionStreetSchema = (schema: StringSchema) =>
  schema.max(30, maxText(30)).required(FORMS_ERROS.required);

export const DirectionCitySchema = (schema: StringSchema) =>
  schema.max(30, maxText(30)).required(FORMS_ERROS.required);

export const DirectionApartmentSchema = (schema: StringSchema) =>
  schema.max(30, maxText(30));

export const DirectionProvinceSchema = (schema: StringSchema) =>
  schema.required(FORMS_ERROS.required);

export const DirectionPhoneSchema = (schema: StringSchema) =>
  schema.required(FORMS_ERROS.required).matches(/^[6-9]\d{8}$/, {
    message: 'Introduce un número válido.',
    excludeEmptyString: false,
  });

export const DirectionPostCodeSchema = (schema: StringSchema) =>
  schema.required(FORMS_ERROS.required).matches(/^\d{5}$/, {
    message: 'Introduce un código postal válido.',
    excludeEmptyString: false,
  });

export const DirectionSchema = object().shape({
  shippingStreet: DirectionStreetSchema(string()),
  shippingCity: DirectionCitySchema(string()),
  shippingApartment: DirectionApartmentSchema(string()),
  shippingProvince: DirectionProvinceSchema(string()),
  shippingPostCode: DirectionPostCodeSchema(string()),
  shippingPhone: DirectionPhoneSchema(string()),
});

export type CustomerDirectionForm = InferType<typeof DirectionSchema> & {
  key: string;
  isDefault: boolean;
};

export type CustomerDirection = {
  isDefault: boolean;
  key: string;
  address1: string;
  address2: string;
  city: string;
  country: 'ES';
  phone: string;
  postCode: string;
  province: string;
};

export const updateDirection = async (
  data: CustomerDirectionForm,
  index: string | number,
  customerID: string,
  isDefault: boolean = false,
) => {
  const province = provincias.find(
    (provincia) => provincia.value === data.shippingProvince,
  )!;

  return await APClient.mutate({
    mutation: WORDPRESS_MUTATION.UPDATE_CUSTOMER_DIRECTION,
    variables: {
      json: JSON.stringify({
        address1: data.shippingStreet,
        address2: data.shippingApartment,
        city: data.shippingCity,
        province: province.label,
        country: 'ES',
        phone: data.shippingPhone,
        postcode: data.shippingPostCode,
        is_default: isDefault,
      }),
      customerID,
      index: `_shipping_address_${index}`,
    },
  });
};

/**
 * Transforms the data we store in the checkout context or we query from wp into data that can be used in the forms on the checkout page and in the my-directions page.
 *
 * This functions needs to be called ALWAYS!! before passing the data from the context or graphql query into a direction form.
 */
export const checkoutDirectionToFormDirection = (
  checkoutData: CustomerDirection,
): CustomerDirectionForm => ({
  key: checkoutData.key,
  shippingCity: checkoutData.city,
  shippingPostCode: checkoutData.postCode,
  shippingPhone: checkoutData.phone,
  shippingProvince: getProvinceValueFromLabel(checkoutData.province),
  shippingStreet: checkoutData.address1,
  shippingApartment: checkoutData.address2,
  isDefault: checkoutData.isDefault,
});

/**
 * Transforms the data that we use in the direction forms in the checkout pages or in the my-directions page into
 * the data we send in the graphql mutations or store in the checkout context.
 *
 * This function needs to be called ALWAYS!! before updating the checkout context or doing a graphql mutation to update the direction of the customer.
 */
export const formDirectionToCheckoutDirection = (
  formData: CustomerDirectionForm,
): CustomerDirection => ({
  key: formData.key,
  address1: formData.shippingStreet,
  address2: formData?.shippingApartment || '',
  city: formData.shippingCity,
  country: 'ES',
  isDefault: formData.isDefault,
  phone: formData.shippingPhone,
  postCode: formData.shippingPostCode,
  province: getProvinceLabelFromValue(formData.shippingProvince) || '',
});

export const addFormData = (keys: object, data: object): FormData => {
  const formData = new FormData();
  Object.values(keys).forEach((key: string) =>
    formData.append(key, data[key as keyof typeof data]),
  );

  return formData;
};

type TypeStatusCF7 =
  | 'init'
  | 'validation_failed'
  | 'acceptance_missing'
  | 'spam'
  | 'aborted'
  | 'mail_sent'
  | 'mail_failed';

const hasCF7Errors = (status: TypeStatusCF7) => status !== 'mail_sent';

type TypeCF7Response = {
  /**
   * Estado de la respuesta
   */
  status: TypeStatusCF7;
  /**
   * Mensaje de la respuesta
   */
  message: string;
  /**
   * ID del formulario
   */
  contact_form_id: number;
  /**
   * Hash del post
   */
  posted_data_hash: string;
  into: string;
  /**
   * Campos inválidos
   */
  invalid_fields: {
    into: string;
    /**
     * Mensaje de error
     */
    message: string;
    idref: string;
    /**
     * ID del error
     */
    error_id: string;
  }[];
};

type TypeConfigSendForm = {
  /**
   * Datos del formulario
   */
  data: FormData;
  /**
   * ID del formulario
   */
  id: number;
};

/**
 * Envia un formulario
 *
 * @param {TypeConfigSendForm} config - Configuración para enviar el formulario
 * @returns {Promise<TypeCF7Response>} - Promesa con la respuesta del servidor
 */
export const sendCF7Form = ({
  id,
  data,
}: TypeConfigSendForm): Promise<TypeCF7Response> =>
  new Promise<TypeCF7Response>((resolve, reject) => {
    fetch(`${process.env.NEXT_PUBLIC_FRONT_URL}/api/forms?id=${id}`, {
      method: 'POST',
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (hasCF7Errors(data.status)) reject(data);
        resolve(data);
      })
      .catch((err) => reject(err));
  });

/**
 * Validate a password
 * @param password The password to validate
 * @param rules Rules to validate the password
 * @returns A string with the error or null if the password is valid
 */
export const passwordValidator = (
  password: string,
  rules: {
    length: number;
    hasNumber: boolean;
    hasLetter: boolean;
    hasLowerCase: boolean;
    hasUpperCase: boolean;
    hasSpecialChar: boolean;
  },
) => {
  const hasNumber = /\d/;
  const hasLetter = /[A-Za-z]/;
  const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  const hasLowerCase = /[a-z]/;
  const hasUpperCase = /[A-Z]/;

  if (password.length < rules.length) {
    return 'NOT_LONG_ENOUGH';
  }
  if (!hasNumber.test(password) && rules.hasNumber) {
    return 'DOES_NOT_CONTAIN_NUMBER';
  }
  if (!hasLetter.test(password) && rules.hasLetter) {
    return 'DOES_NOT_CONTAIN_LETTER';
  }
  if (!hasLowerCase.test(password) && rules.hasLowerCase) {
    return 'DOES_NOT_CONTAIN_LOWER_CASE';
  }
  if (!hasUpperCase.test(password) && rules.hasUpperCase) {
    return 'DOES_NOT_CONTAIN_UPPER_CASE';
  }
  if (!hasSpecialCharacter.test(password) && rules.hasSpecialChar) {
    return 'DOES_NOT_CONTAIN_SPECIAL_CHARACTER';
  }

  return null;
};
