import { memo } from 'react';
import { useSortBy } from 'react-instantsearch';
import { Listbox } from '@headlessui/react';

import { SelectOption } from '@/atoms';
import SelectButton from '@/atoms/selectButton';

import { gtmPush, normalizeString } from '@/lib/utils';

import type { FunctionComponent } from 'react';
import type { TypeSortByProps } from './types';

/**
 * SortBy
 */
export const SortBy: FunctionComponent<TypeSortByProps> = memo(
  ({ label, items, className = '' }: TypeSortByProps) => {
    const { currentRefinement, options, refine } = useSortBy({
      items,
    });
    const selected = items.filter((value) => value.value === currentRefinement);
    const selectedLabel = selected[0]?.label ?? label;

    return (
      <Listbox
        value={currentRefinement}
        onChange={(selected) => {
          refine(selected);
          gtmPush({
            event: 'order_products',
            eventData: {
              label: normalizeString(selected).replaceAll(' ', '_'),
            },
          });
        }}
        as="div"
        className={`relative ${className}`}
      >
        {({ open }) => (
          <>
            <SelectButton
              label={selectedLabel}
              placeholder={selectedLabel}
              open={open}
              error=""
              theme="secondary"
            />

            <SelectOption options={options} theme="secondary" />
          </>
        )}
      </Listbox>
    );
  },
);

SortBy.displayName = 'SortBy';
