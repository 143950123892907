import { routes } from '@/lib/routes';

import type { TypeCardBlogProps } from '@/molecules/card-blog';

export const postCardTransformer = (
  post: any,
): Omit<TypeCardBlogProps, 'excerpt'> & { excerpt?: string | null } => {
  const image = post?.featuredImage?.node
    ? {
        src: post?.featuredImage?.node?.sourceUrl as string,
        alt: post?.featuredImage?.node?.altText as string,
      }
    : null;

  return {
    image,
    date: post.date as string,
    readingTime: post?.cptBlog?.readingTime || null,
    title: post.title as string,
    excerpt: (post.excerpt as string) || null,
    href: routes.blog.post(
      post?.primaryCategory?.path
        ?.replace('/category', '')
        ?.replaceAll('/', '') || '',
      post.slug,
    ),
    badge: post?.primaryCategory?.name || null,
  };
};
