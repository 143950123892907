export const routes = {
  index: '/',
  cart: '/cesta',
  checkout: {
    datos: '/finalizar-compra',
    datosGuest: '/finalizar-compra/paso/datos',
    direccion: '/finalizar-compra/paso/direccion',
    gracias: '/finalizar-compra/paso/gracias',
    confirmation: '/finalizar-compra/paso/confirmacion',
  },
  micuenta: {
    index: '/mi-cuenta',
    editAccount: '/mi-cuenta/editar-cuenta',
    changePassword: '/mi-cuenta/cambiar-contrasena',
    address: '/mi-cuenta/mis-direcciones',
    paymentMethods: '/mi-cuenta/formas-de-pago',
    purchases: {
      index: '/mi-cuenta/mis-compras',
      order: (id: number | string) => `/mi-cuenta/mis-compras/${id}`,
    },
    myExperiences: '/mi-cuenta/mis-experiencias',
    mySubscriptions: {
      index: '/mi-cuenta/mis-suscripciones',
      order: (id: number | string) => `/mi-cuenta/mis-suscripciones/${id}`,
    },
    contact: '/mi-cuenta/contacto',
    noPayment: '/mi-cuenta/no-pago',
    payOrder: '/mi-cuenta/pagar-orden',
    coupons: {
      employee: '/mi-cuenta/cupones-empleado',
    },
    tribu: '/mi-cuenta/tribu-de-amantes-artesanos',
  },
  contact: '/contacto',
  faq: '/faqs',
  legalNoticePrivacyPolicy: '/aviso-legal-politica-privacidad',
  privacyPolicy: '/politica-privacidad',
  cookiesPolicy: '/politica-de-cookies',
  condicionesCompra: '/condiciones-generales-compra-move',
  condicionesUso: '/condiciones-uso-marketplace',
  condicionesTribu: '/condiciones-generales-tribu-artesanos',
  joinBigCrafters: '/unete-bigcrafters',
  about: '/sobre-nosotros',
  aceleracionArtesanos: '/programa-aceleracion-digital-artesanos',
  sellers: {
    seller: (id: number | string) => `/vendedores/${id}`,
  },
  brand: (id: number | string) => `/marcas/${id}`,
  product: (id: number | string) => `/producto/${id}`,
  blog: {
    index: '/blog',
    page: (page: number | string) => `/blog/${page}`,
    category: {
      index: (slug: string) => `/blog/${slug}`,
      page: (slug: string, page: number | string) => `/blog/${slug}/${page}`,
    },
    post: (category: string, id: number | string) => `/blog/${category}/${id}`,
  },
  employees: {
    login: '/empleados',
    page: (id: number | string) => `/empleados/${id}`,
  },
};
