import { useCallback, useEffect, useRef, useState } from "react";
import type { WORDPRESS_MUTATION } from "../graphql/enums";
import GQLClient from "../graphql/utils";

type MutationOptions<TData, TVariables> = {
  variables?: TVariables;
  onCompleted?: (data: TData) => void;
  onError?: (reason: string) => void;
  signal?: AbortSignal;
};

type MutationResult<TData> = {
  data?: TData;
  error?: string;
  loading: boolean;
};

type FetchResult<TData> = {
  data?: TData;
  error?: string;
};

type MutationTuple<TData, TVariables> = [
  mutate: (options: MutationOptions<TData, TVariables>) => Promise<FetchResult<TData>>,
  result: MutationResult<TData>
];

export function useMutation<TData extends object = any, TVariables extends object = any>(mutation: WORDPRESS_MUTATION, options?: MutationOptions<TData, TVariables>): MutationTuple<TData, TVariables> {
  const [mutationResult, setMutationResult] = useState<MutationResult<TData>>({
    loading: false,
    data: undefined,
    error: undefined
  });

  const mutationRef = useRef({ mutation, options, mutationResult, isMounted: true, mutationId: 0 });

  const execute = useCallback(async function(executeOptions?: MutationOptions<TData, TVariables>) {
    if (!mutationRef.current.mutationResult.loading && mutationRef.current.isMounted) {
      setMutationResult((mutationRef.current.mutationResult = { loading: true, data: undefined, error: undefined }));
    }

    const mutationOptions = {
      mutation: mutationRef.current.mutation,
      variables: executeOptions?.variables || mutationRef.current.options?.variables,
      signal: executeOptions?.signal || mutationRef.current.options?.signal,
      onError: executeOptions?.onError || mutationRef.current.options?.onError,
      onCompleted: executeOptions?.onCompleted || mutationRef.current.options?.onCompleted,
    };

    const mutationId = ++mutationRef.current.mutationId;

    return GQLClient.mutate(mutationOptions)
      .then((response) => {
        const { data, error } = response;

        if (error && mutationOptions?.onError) {
          mutationOptions.onError(error);
        }

        if (mutationId === mutationRef.current.mutationId) {
          setMutationResult((mutationRef.current.mutationResult = { loading: false, data, error }));
        }

        if (!error && mutationOptions?.onCompleted) {
          mutationOptions.onCompleted(data);
        }

        return response;
      })
      .catch((error) => {
        if (mutationId === mutationRef.current.mutationId) {
          setMutationResult((mutationRef.current.mutationResult = { loading: false, data: undefined, error }));
        }

        if (mutationOptions?.onError) {
          mutationOptions.onError(error);
        }

        throw error;
      })
  }, []);


  useEffect(() => {
    const current = mutationRef.current;
    current.isMounted = true;
    
    return () => {
      current.isMounted = false;
    }
  }, []);

  useEffect(() => {
    mutationRef.current.mutation = mutation;
    mutationRef.current.options = options;
  });

  return [execute, mutationResult];
}