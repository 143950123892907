/* eslint-disable sonarjs/no-nested-template-literals */
import { Fragment, useContext, useEffect, useState } from 'react';

import { Info } from '@/icons';
import { NextLink } from '@/atoms';
import {
  CartLine,
  CartLineFooter,
  CartLineHeader,
  CartShippingHeader,
  Modal,
} from '@/molecules';

import { InitPropsProvider } from '@/components/providers/initPropsProvider';
import { useCartDispatch } from '@/lib/hooks';
import { routes } from '@/lib/routes';
import {
  debug,
  gtmPushSelectItem,
  imageWithFallback,
  isOperator,
  priceToNumber,
  removeBaseURL,
  showInvoice,
  toCurrency,
} from '@/lib/utils';
import { normalizeProduct } from '@/lib/utils/gtm/products';
import FallBackIMG from '@/assets/images/fallback-product-card.jpg';
import { repeat } from '@/stories/utils';

import type { TypeCartLinesProps } from './types';

export const CartLines = ({
  className = '',
  loading,
  cart,
  editable = true,
  type,
  hookSeller,
  hookLineHeader,
  gtm,
}: TypeCartLinesProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const propsProvider = useContext(InitPropsProvider);
  const bcOptions = propsProvider?.options;

  const { update, remove } = useCartDispatch();

  useEffect(
    () => () => {
      setIsUpdating(false);
    },
    [],
  );

  return (
    <>
      <Modal open={showModal} setOpen={setShowModal} unmount>
        <h2 className="mb-4 md:mb-6">GASTOS DE ENVÍO</h2>

        <div className="prose">
          <p>
            El coste de envío es de 5 € IVA incluido. El coste del envío es
            gratuito para pedidos superiores a 50 €*.
          </p>

          <p>
            Envío solo a España peninsular. Los pedidos tendrán un límite de
            peso de {bcOptions.weightMaxCart} kg. Para pedidos de un volumen
            superior póngase en
            <NextLink href={routes.contact}>
              <a className="text-secondary-500">
                {' '}
                contacto con nuestro servicio de Atención al Cliente.
              </a>
            </NextLink>
          </p>

          <p>* Límite sujeto a promociones.</p>
        </div>
      </Modal>

      <div
        className={`flex flex-col gap-4 md:gap-6 ${className}`}
        data-playwright="cartLines"
      >
        {!loading
          ? type === 'cart'
            ? cart.map((seller) => (
                <div className="flex flex-col gap-4" key={seller.id}>
                  <div className="grid w-full grid-cols-1 gap-px">
                    <CartShippingHeader title={`Vendido por ${seller.name}`} />
                    {Array.from(seller.orders.values()).map((order, index) => (
                      <Fragment key={index}>
                        <CartLineHeader
                          title={order.shippingEstimateDate.date}
                          {...(hookLineHeader && {
                            buttons:
                              typeof hookLineHeader === 'function'
                                ? hookLineHeader(seller, order)
                                : hookLineHeader,
                          })}
                        />
                        {order.offers.map((offer) => (
                          <CartLine
                            key={offer.id}
                            editable
                            disabled={isUpdating}
                            image={imageWithFallback(
                              offer.product?.image?.sourceUrl ?? '',
                              offer.product?.image?.altText ?? '',
                              FallBackIMG.src,
                            )}
                            name={offer.product.name}
                            bundleItems={offer.bundleItems}
                            {...(!!offer.product.description && {
                              description: offer.product.description,
                            })}
                            quantity={offer.quantity}
                            {...(editable && {
                              subtractDisabled:
                                offer.quantity ===
                                (offer.product.woosbMinQty ?? 1),
                              addDisabled:
                                offer.product?.exactQuantity &&
                                offer.product.exactQuantity?.length > 0 &&
                                offer.product.exactQuantity.indexOf(
                                  offer.quantity,
                                ) +
                                  1 ===
                                  offer.product.exactQuantity.length,
                              onSubtract: async () => {
                                setIsUpdating(true);
                                const fixedQty = offer.product?.exactQuantity;
                                let qty = offer.quantity - 1;

                                if (fixedQty) {
                                  const cursor = fixedQty.indexOf(
                                    offer.quantity,
                                  );

                                  if (cursor === -1 || cursor - 1 < 0) {
                                    setIsUpdating(false);
                                    return;
                                  }

                                  qty = fixedQty[cursor - 1]!; // We know this won't go outbounds.
                                }

                                await update({
                                  keyCartProduct: offer.key,
                                  quantity: qty,
                                  gtm: {
                                    index: index + 1,
                                    listName: 'cart',
                                    ...gtm,
                                  },
                                }).catch((error) => debug(error));
                                setIsUpdating(false);
                              },
                              onAdd: async () => {
                                setIsUpdating(true);
                                const fixedQty = offer.product?.exactQuantity;
                                let qty = offer.quantity + 1;

                                if (fixedQty) {
                                  const cursor = fixedQty.indexOf(
                                    offer.quantity,
                                  );

                                  if (
                                    cursor === -1 ||
                                    cursor + 1 >= fixedQty.length
                                  ) {
                                    setIsUpdating(false);
                                    return;
                                  }

                                  qty = fixedQty[cursor + 1]!; // We know this won't go outbounds.
                                }

                                await update({
                                  keyCartProduct: offer.key,
                                  quantity: qty,
                                  gtm: {
                                    index: index + 1,
                                    listName: 'cart',
                                    ...gtm,
                                  },
                                }).catch((error) => debug(error));
                                setIsUpdating(false);
                              },
                              onRemove: async () => {
                                setIsUpdating(true);
                                await remove({
                                  keyCartProduct: offer.key,
                                  gtm: {
                                    index: index + 1,
                                    listName: 'cart',
                                    ...gtm,
                                  },
                                }).catch((error) => debug(error));
                                setIsUpdating(false);
                              },
                            })}
                            price={toCurrency(offer.total)}
                            regularPrice={toCurrency(
                              priceToNumber(offer.product.regularPrice) *
                                offer.quantity,
                            )}
                            link={removeBaseURL(offer.product.link)}
                            className="bg-bg-secondary"
                            onClick={() => {
                              gtmPushSelectItem({
                                item: normalizeProduct({
                                  offer,
                                  index: index + 1,
                                  itemListName: 'cart',
                                }),
                                itemListName: 'cart',
                                index: index + 1,
                              });
                            }}
                          />
                        ))}
                      </Fragment>
                    ))}
                    <CartLineFooter
                      className="bg-bg-secondary"
                      price={seller.shippingTotal}
                      howMuchLeftForFreeShipping={
                        seller.minFreeAmount - seller.total
                      }
                      shippingLink={seller.path}
                      {...(isOperator(seller.name) && {
                        shippingIcon: (
                          <button onPointerDown={() => setShowModal(true)}>
                            <Info width={20} height={20} />
                          </button>
                        ),
                      })}
                    />
                    {typeof hookSeller === 'function'
                      ? hookSeller(seller)
                      : hookSeller}
                  </div>
                </div>
              ))
            : type === 'order'
              ? cart.map((seller) => (
                  <div className="flex flex-col gap-4" key={seller.id}>
                    <div className="grid w-full grid-cols-1 gap-px">
                      <CartShippingHeader
                        title={`Vendido por ${seller.name}`}
                        description={`Nº ${isOperator(seller.name) ? `BC-${seller.orderNumber}` : `WPT-${seller.orderNumber}-A`}`}
                        {...(isOperator(seller.name) &&
                          showInvoice(seller.status) && {
                            orderId: seller.orderNumber,
                            requestInvoice: true,
                          })}
                      />
                      {Array.from(seller.orders.values()).map(
                        (order, index) => (
                          <Fragment key={index}>
                            <CartLineHeader
                              title={order.shippingEstimateDate.date}
                              status={seller.status}
                              {...(hookLineHeader && {
                                buttons:
                                  typeof hookLineHeader === 'function'
                                    ? hookLineHeader(seller, order)
                                    : hookLineHeader,
                              })}
                            />
                            {order.offers.map((offer) => (
                              <CartLine
                                key={offer.id}
                                editable
                                disabled={isUpdating}
                                image={imageWithFallback(
                                  offer.product?.image?.sourceUrl ?? '',
                                  offer.product?.image?.altText ?? '',
                                  FallBackIMG.src,
                                )}
                                name={offer.product.name}
                                bundleItems={offer.bundleItems}
                                quantity={
                                  offer.remainingRefundQuantity ??
                                  offer.quantity
                                }
                                price={toCurrency(
                                  offer?.remainingRefund ?? offer.total,
                                )}
                                link={removeBaseURL(offer.product.link)}
                                className="bg-bg-secondary"
                              />
                            ))}
                          </Fragment>
                        ),
                      )}

                      {typeof hookSeller === 'function'
                        ? hookSeller(seller)
                        : hookSeller}
                    </div>
                  </div>
                ))
              : null
          : repeat(
              2,
              <div className="flex flex-col gap-4">
                <div className="grid w-full grid-cols-1 gap-px bg-bg-secondary">
                  <CartLineHeader loading />
                  <CartLine loading />
                </div>
              </div>,
            )}
      </div>
    </>
  );
};
