import { useState } from 'react';

import { Radio } from '@/molecules';

import { WORDPRESS_MUTATION } from '@/lib/graphql/enums';
import APClient from '@/lib/graphql/utils';

import type { CustomerDirection } from '@/lib/utils';
import type { FormikErrors } from 'formik';

type DirectionSelector = {
  directions: CustomerDirection[];
  errors: FormikErrors<{ direction?: number }>;
  isLoading: boolean;
  onUpdate: (seletecDirection: CustomerDirection) => void;
  onNewDirection: () => void;
  onDeleteSucess?: (directionkey: string) => void;
  onDeleteError?: (error: any) => void;
};

export const DirectionSelector = ({
  directions,
  errors,
  isLoading,
  onUpdate,
  onNewDirection,
  onDeleteSucess,
  onDeleteError,
}: DirectionSelector) => {
  // We don't want to deal with reace conditions for now so we diabled all the buttons while deleting a direction.
  // TODO: When we migrate to React 19 we could rework this using the useOptimistic hook.
  const [deleting, setDeleting] = useState(false);

  const onDelete = async (directionKey: string) => {
    setDeleting(true);
    await APClient.mutate({
      mutation: WORDPRESS_MUTATION.DELETE_CUSTOMER_DIRECTION,
      variables: {
        key: directionKey,
      },
    })
      .then(() => onDeleteSucess && onDeleteSucess(directionKey))
      .catch((error) => onDeleteError && onDeleteError(error))
      .finally(() => setDeleting(false));
  };

  if (isLoading) {
    return (
      <h2 className="u-headline u-headline--h4 mb-6 lg:mb-8 lg:mt-11">
        Cargando tus Direcciones...
      </h2>
    );
  }

  return (
    <>
      <Radio
        name="direction"
        label=""
        id="direction"
        testid="directions"
        radioClassName="bg-white border border-primary-100 py-4 px-6 pr-5 !m-0"
        error={errors.direction}
        className="grid max-w-[500px] grid-cols-1 gap-2 md:grid-cols-2"
        labelClass="h-full"
        placeholder=""
        values={directions?.map((direction, index) => ({
          label: (
            <div className="ml-3 flex h-full flex-col gap-4 md:ml-px">
              <p className="u-body u-body--s grow text-primary-400">
                {direction.address1}, {direction.address2}
                <br />
                {direction.postCode} {direction.city}, {direction.province}
                <br />
                Teléfono: {direction.phone}
              </p>
              <div className="flex gap-2">
                <button
                  disabled={deleting}
                  type="button"
                  className="u-actions u-actions--chips cursor-pointer font-bold hover:opacity-50 disabled:opacity-30"
                  onClick={() => onUpdate(direction)}
                >
                  Modificar
                </button>

                <button
                  disabled={deleting}
                  type="button"
                  className="u-actions u-actions--chips cursor-pointer font-bold hover:opacity-50 disabled:opacity-30"
                  onClick={() => onDelete(direction.key)}
                >
                  {deleting ? 'Eliminando...' : 'Eliminar'}
                </button>
              </div>
            </div>
          ),
          value: index,
        }))}
      />

      <p
        className="u-actions u-actions--link mb-4 mt-6 cursor-pointer underline"
        onClick={onNewDirection}
      >
        Añadir dirección de envío
      </p>
    </>
  );
};
