import { createContext, useEffect, useReducer, useState } from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

import { FORM_STORAGE_KEY } from '@/organisms/forms/checkout-forms/CheckoutDirectionForm';

import { WORDPRESS_QUERY } from '@/lib/graphql/enums';
import { useLazyQuery } from '@/lib/hooks';
import { routes } from '@/lib/routes';
import {
  formDirectionToCheckoutDirection,
  transformProviderToWp,
} from '@/lib/utils';

import type { CustomerDirection, CustomerDirectionForm } from '@/lib/utils';
import type { ReactNode } from 'react';

type UserDirectionsQuery = {
  customer: {
    id: string;
    customerShippingAddresses: CustomerDirection[];
  };
};

export type Checkout = {
  firstName: string;
  lastName: string;
  email: string;
  isGift: boolean;
  giftEmail: string; // Gift form exclusive field
  shipping_firstname: string; // Gift form exclusive field
  shipping_lastname: string; // Gift form exclusive field
  notas: string; // Gift form exclusive field
  shipping_street: string;
  shipping_city: string;
  shipping_apartment: string;
  shipping_province: string;
  shipping_cp: string;
  shipping_phone: string;
  billing_street: string;
  billing_city: string;
  billing_apartment: string;
  billing_province: string;
  billing_cp: string;
  billing_phone: string;
  paymentMethod: string;
  allDirections: CustomerDirection[];
  customerID: string;
  sameDirectionAsShipping: boolean;
  isSubscription: boolean; // Are we using this?
  subscriptionSchema: string; // Are we using this?
  createUser: boolean;
};

type Action =
  | {
      type: 'UPDATE_CHECKOUT_DATA';
      payload: {
        checkout: Partial<Checkout>;
      };
    }
  | {
      type: 'REMOVE_DIRECTION';
      payload: {
        directionKeyToRemove: string | number;
      };
    }
  | {
      type: 'UPDATE_DIRECTION';
      payload: {
        direction: CustomerDirection;
      };
    }
  | {
      type: 'ADD_DIRECTION';
      payload: {
        direction: CustomerDirection;
      };
    };

export const CHECKOUT_INITIAL_STATE: Checkout = {
  firstName: '',
  lastName: '',
  email: '',
  giftEmail: '',
  isGift: false,
  shipping_firstname: '',
  shipping_lastname: '',
  shipping_street: '',
  shipping_city: '',
  shipping_apartment: '',
  shipping_province: '',
  shipping_cp: '',
  shipping_phone: '',
  billing_street: '',
  billing_city: '',
  billing_apartment: '',
  billing_province: '',
  billing_cp: '',
  billing_phone: '',
  paymentMethod: 'Tarjeta',
  allDirections: [], // Delete this when directions data comes inside user data.
  customerID: '',
  isSubscription: false,
  subscriptionSchema: '',
  createUser: false,
  notas: '',
  sameDirectionAsShipping: true,
};

const CHECKOUT_COOKIE_NAME = 'checkoutData';
const COOKIES = new Cookies();

// ~~~~~~~~~~ CHECKOUT FLAGS ~~~~~~~~~~ //
const SKIP_ALL_CHECKOUT_STEPS = true;

export const CheckoutContext = createContext<{
  checkout: Checkout;
  setCheckoutData: (newData: Partial<Checkout>) => void;
  /**
   * Removes the direction from the checkout context. This function DOESN'T remove the direction in wp, that has to be done elsewhere.
   */
  removeDirection: (directionKeyToRemove: string) => void;
  /**
   * Updates the direction on the checkout context. This function DOESN'T update the direction in wp, that has to be done elsewhere.
   *
   * The data transformation from direction form to context data is made inside the function.
   */
  updateDirection: (direction: CustomerDirectionForm) => void;
  /**
   * Adds a new direction to the checkout context. This function DOESN'T add the direction in wp, that has to be done elsewhere.
   *
   * The data transformation from direction form to context data is made inside the function.
   */
  addDirection: (direction: CustomerDirectionForm) => void;
  resetSubscriptionAndGiftData: () => void;
  getWpCheckoutData: () => ReturnType<typeof transformProviderToWp> & {
    [key: PropertyKey]: any;
  };
  startCheckout: () => void;
  isLoading: boolean;
  oneClickPaymentAvailable: boolean;
} | null>(null);

const loadInitialState = () => {
  const checkoutDataCookie = COOKIES.get(CHECKOUT_COOKIE_NAME);
  if (!checkoutDataCookie) {
    COOKIES.set(CHECKOUT_COOKIE_NAME, CHECKOUT_INITIAL_STATE, { path: '/' });
    return CHECKOUT_INITIAL_STATE;
  }

  const cookieDataKeys = Object.keys(checkoutDataCookie);
  const initialStateKeys = Object.keys(CHECKOUT_INITIAL_STATE);

  // We check that the cookie data has all the keys.
  if (cookieDataKeys.length === initialStateKeys.length) {
    return checkoutDataCookie;
  }

  COOKIES.set(CHECKOUT_COOKIE_NAME, CHECKOUT_INITIAL_STATE, { path: '/' });
  return CHECKOUT_INITIAL_STATE;
};

const reducer = (checkout: Checkout, action: Action): Checkout => {
  let newCheckout: Checkout;

  switch (action.type) {
    case 'UPDATE_CHECKOUT_DATA':
      newCheckout = { ...checkout, ...action.payload.checkout };
      break;
    case 'REMOVE_DIRECTION':
      newCheckout = {
        ...checkout,
        allDirections: checkout.allDirections.filter(
          (direction) => direction.key !== action.payload.directionKeyToRemove,
        ),
      };
      break;
    case 'ADD_DIRECTION':
      newCheckout = {
        ...checkout,
        allDirections: [...checkout.allDirections, action.payload.direction],
      };
      break;
    case 'UPDATE_DIRECTION':
      const updatedDirection = action.payload.direction;
      newCheckout = {
        ...checkout,
        allDirections: checkout.allDirections.map((direction) => {
          if (direction.key === updatedDirection.key) {
            return updatedDirection;
          } else return direction;
        }),
      };
      break;
    default:
      throw Error('Action type unknow');
  }

  // Why not use the local storage?
  COOKIES.set(CHECKOUT_COOKIE_NAME, newCheckout, { path: '/' });
  return newCheckout;
};

export const CheckoutProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [checkoutData, dispatch] = useReducer(
    reducer,
    CHECKOUT_INITIAL_STATE,
    loadInitialState,
  );

  const router = useRouter();
  const session = useSession();
  const [isLoading, setIsLoading] = useState(session.status === 'loading');

  const [fetchUserDirections] = useLazyQuery<UserDirectionsQuery>(
    WORDPRESS_QUERY.USER_DIRECTIONS,
    {
      onCompleted: ({ customer: { customerShippingAddresses, id } }) => {
        setCheckoutData({
          allDirections: customerShippingAddresses,
          customerID: id,
        });

        setIsLoading(false);
      },
    },
  );

  const setCheckoutData = (newData: Partial<Checkout>) => {
    dispatch({ type: 'UPDATE_CHECKOUT_DATA', payload: { checkout: newData } });
  };

  const removeDirection = (directionKeyToRemove: string) => {
    dispatch({
      type: 'REMOVE_DIRECTION',
      payload: {
        directionKeyToRemove,
      },
    });
  };

  const addDirection = (direction: CustomerDirectionForm) => {
    const transformedDirection = formDirectionToCheckoutDirection(direction);

    const newKey = checkoutData.allDirections.at(-1)?.key || '0';

    dispatch({
      type: 'ADD_DIRECTION',
      payload: {
        direction: {
          ...transformedDirection,
          key: String(Number(newKey) + 1),
          country: 'ES',
          isDefault: false,
        },
      },
    });
  };

  const updateDirection = (direction: CustomerDirectionForm) => {
    const transformedDirection = formDirectionToCheckoutDirection(direction);

    dispatch({
      type: 'UPDATE_DIRECTION',
      payload: {
        direction: {
          ...transformedDirection,
          country: 'ES',
        },
      },
    });
  };

  const resetSubscriptionAndGiftData = () => {
    setCheckoutData({
      isGift: false,
      giftEmail: '',
      isSubscription: false,
      subscriptionSchema: '',
    });
  };

  const getWpCheckoutData = () => transformProviderToWp(checkoutData);

  const startCheckout = async () => {
    if (isLoading) return;

    setIsLoading(true);

    // In the direction step in the checkout we store the form state in certain conditions and we don't want the data
    // to persist between checkout "sessions" and even tho we store the data in the sessionStorage, meaning it should be clean up when the user close the tab,
    // we still remove it by hand just to be sure just in the case the user starts a new checkout "session" without closing the tab.
    sessionStorage.removeItem(FORM_STORAGE_KEY);

    if (session.status !== 'authenticated') {
      router.push(routes.checkout.datos);
      return;
    }

    setCheckoutData({
      firstName: (session?.data?.user as any)?.firstName || '',
      lastName: (session?.data?.user as any)?.lastName || '',
      email: (session?.data?.user as any)?.email || '',
    });

    if (SKIP_ALL_CHECKOUT_STEPS) {
      // If the user is logged in and has a default direction we set the checkout data
      // so the user can skip all the steps and go to the confirmation step in on click.

      if (checkoutData.isGift) {
        router.push(routes.checkout.direccion);
        return;
      }

      // We fetch the user directions to check if the user has updated their default direction.
      const { data } = await fetchUserDirections({});

      if (!data) {
        router.push(routes.checkout.direccion);
        return;
      }

      const { customerShippingAddresses, id } = data.customer;

      if (customerShippingAddresses.length === 0) {
        router.push(routes.checkout.direccion);
        return;
      }

      const defaultDirection = customerShippingAddresses.find(
        (direction) => direction.isDefault,
      );

      if (!defaultDirection) {
        router.push(routes.checkout.direccion);
        return;
      }

      setCheckoutData({
        firstName: (session?.data?.user as any)?.firstName || '',
        lastName: (session?.data?.user as any)?.lastName || '',
        email: (session?.data?.user as any)?.email || '',
        shipping_firstname: (session?.data?.user as any)?.firstName || '',
        shipping_lastname: (session?.data?.user as any)?.lastName || '',
        shipping_street: defaultDirection.address1,
        shipping_city: defaultDirection.city,
        shipping_apartment: defaultDirection.address2,
        shipping_province: defaultDirection.province,
        shipping_cp: defaultDirection.postCode,
        shipping_phone: defaultDirection.phone,
        billing_street: defaultDirection.address1,
        billing_city: defaultDirection.city,
        billing_apartment: defaultDirection.address2,
        billing_province: defaultDirection.province,
        billing_cp: defaultDirection.postCode,
        billing_phone: defaultDirection.phone,
        sameDirectionAsShipping: true,
        allDirections: customerShippingAddresses,
        customerID: id,
      });

      router.push(routes.checkout.confirmation);
      return;
    }

    router.push(routes.checkout.direccion);
  };

  useEffect(() => {
    if (session.status === 'authenticated') {
      fetchUserDirections();
    } else if (session.status === 'unauthenticated') {
      setIsLoading(false);
    }
  }, [fetchUserDirections, session.status]);

  useEffect(() => {
    function handleRouterEvent() {
      setIsLoading(false);
    }

    router.events.on('routeChangeComplete', handleRouterEvent);

    return () => {
      router.events.off('routeChangeComplete', handleRouterEvent);
    };
  }, [router.events]);

  return (
    <CheckoutContext.Provider
      value={{
        checkout: checkoutData,
        setCheckoutData,
        resetSubscriptionAndGiftData,
        getWpCheckoutData,
        startCheckout,
        removeDirection,
        addDirection,
        updateDirection,
        isLoading: isLoading,
        oneClickPaymentAvailable: false,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
