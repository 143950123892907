import type { FunctionComponent } from 'react';
import type { TypeTextAreaProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const TextAreaAtom: FunctionComponent<TypeTextAreaProps> = ({
  name,
  handleBlur,
  handleChange,
  handleFocus,
  field,
  rows = 2,
  cols = 20,
  disabled,
  ...props
}: TypeTextAreaProps) => (
  <textarea
    onBlur={handleBlur}
    onChange={handleChange}
    onFocus={handleFocus}
    name={name}
    rows={rows}
    cols={cols}
    disabled={disabled}
    {...field}
    {...props}
  />
);
