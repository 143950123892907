import { InstantSearchSSRProvider } from 'react-instantsearch';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';
import singletonRouter from 'next/router';

import { InstantSearch, ListOfProducts } from '@/components/algolia';
import { isNumber } from '@/lib/utils';

import type { BreadCrumbs } from '@/lib/utils';
import type { ParsedQs } from 'qs';
import type { FunctionComponent } from 'react';
import type { InstantSearchServerState } from 'react-instantsearch';

export type TypeCategoryProps = {
  serverState?: InstantSearchServerState;
  filters?: string;
  categoryAtribute?: string;
  url: string;
  userId?: string;
  title: string;
  description?: string;
  filtrableFields: any[];
  breadcrumbs?: BreadCrumbs;
  showAlwaysFilters?: boolean;
  onClose?: () => void;
  /**
   * Mostrar el titulo como H1
   */
  showTitleAsH1?: boolean;
  /**
   * itemListName
   */
  itemListName?: string;
  isVirtualPagination?: boolean;
};

export const Category: FunctionComponent<TypeCategoryProps> = ({
  serverState,
  url,
  showAlwaysFilters = false,
  isVirtualPagination = false,
  ...props
}: TypeCategoryProps) => (
  <InstantSearchSSRProvider {...serverState}>
    <InstantSearch
      routing={{
        router: createInstantSearchRouterNext({
          singletonRouter,
          serverUrl: `${process.env.NEXT_PUBLIC_FRONT_URL}${url}`,
          routerOptions: {
            cleanUrlOnDispose: false,
            ...(!isVirtualPagination && {
              createURL: ({ qsModule, location, routeState }) => {
                const currentPage = location.pathname.split('/').pop();
                const hasCurrentPage = currentPage && isNumber(currentPage);
                const realPathName = hasCurrentPage
                  ? location.pathname.replace(`/${currentPage}`, '')
                  : location.pathname;

                const queriesFromUrl = qsModule.parse(location.search.slice(1));

                const utmQueries = Object.keys(queriesFromUrl)
                  .filter((key) => !Object.keys(routeState).includes(key))
                  .reduce((acc: ParsedQs, cur) => {
                    acc[cur] = queriesFromUrl[cur];
                    return acc;
                  }, {});

                const { page } = routeState.hdrwp_product_catalog ?? {};

                const newUrl = new URL(location.href);

                newUrl.pathname = `${realPathName}${
                  page && page > 1 ? `/${page}` : ''
                }`;

                const searchParams = new URLSearchParams(
                  qsModule.stringify({
                    ...routeState,
                    ...utmQueries,
                  }),
                );

                searchParams.delete('hdrwp_product_catalog[page]');

                newUrl.search = searchParams.toString();

                return newUrl.toString();
              },
              parseURL: ({ qsModule, location }) => {
                const posiblePage = location.pathname.split('/').pop();
                const page = Number(posiblePage) || undefined;
                const {
                  hdrwp_product_catalog: algoliaURLData,
                }: {
                  hdrwp_product_catalog?: {
                    sortBy?: string;
                    refinementList?: {
                      [key: string]: Array<string>;
                    };
                    query?: string;
                    range?: {
                      price: string;
                    };
                  };
                } = qsModule.parse(location.search.slice(1));

                return {
                  hdrwp_product_catalog: {
                    ...algoliaURLData,
                    ...(page && { page }),
                  },
                };
              },
            }),
            writeDelay: 400,
          },
        }),
      }}
    >
      <ListOfProducts
        type="category"
        showFilters={
          (serverState &&
            (serverState.initialResults[
              process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX_NAME ?? ''
            ]?.results?.[0]?.nbHits ?? 0) > 10) ||
          showAlwaysFilters
        }
        {...props}
      />
    </InstantSearch>
  </InstantSearchSSRProvider>
);

Category.displayName = 'Category';
