import { useFormikContext } from 'formik';

import { Checkbox, Input, Select, TextArea } from '@/molecules';

import { provincias } from '@/lib/utils';

import type { CheckoutDirectionFormValues } from './utils';

export const GiftForm = () => {
  const { errors } = useFormikContext<CheckoutDirectionFormValues>();

  return (
    <>
      <Checkbox
        name="isGift"
        className="mt-4 w-full"
        values={[
          {
            value: 'true',
            label: 'Es para regalar',
          },
        ]}
        id="isGift"
        error={errors.isGift}
      />

      <div className="grid grid-cols-1 gap-x-5 gap-y-2 md:grid-cols-2">
        <div className="col-span-full">
          <p className="u-label u-label--xs my-4">
            <b>
              Introduce los datos de la persona a la que va dirigido el regalo
            </b>{' '}
            y ¡nosotros nos encargamos de hacérselo llegar y sorprenderle! Esta
            opción no incluye un envoltorio especial para regalo, ni tarjeta con
            mensaje para el destinatario.
          </p>
        </div>

        <Input
          name="giftName"
          placeholder="Nombre *"
          id="giftName"
          error={errors?.giftName}
        />

        <Input
          name="giftApellidos"
          placeholder="Apellidos *"
          id="giftApellidos"
          error={errors?.giftApellidos}
        />

        <Input
          name="giftStreet"
          placeholder="Calle y número *"
          id="giftStreet"
          error={errors?.giftStreet}
        />

        <Input
          name="giftCity"
          placeholder="Ciudad *"
          id="giftCity"
          error={errors?.giftCity}
        />

        <Input
          name="giftApartment"
          placeholder="Apartamento, habitación, etc."
          id="giftApartment"
          error={errors?.giftApartment}
        />

        <Select
          name="giftProvince"
          placeholder="Provincia *"
          id="giftProvince"
          options={provincias}
          error={errors?.giftProvince}
        />

        <Input
          name="giftCp"
          placeholder="Código postal *"
          id="giftCp"
          error={errors?.giftCp}
        />

        <Input
          name="giftPhone"
          placeholder="Teléfono *"
          id="giftPhone"
          error={errors?.giftPhone}
        />

        <p className="u-label u-label--xs mt-2 md:col-span-2">
          <b>
            Indícanos un correo electrónico y enviaremos un email con tu mensaje
            a quien va a recibir el regalo de tu parte
          </b>{' '}
          sin indicar el producto. Si NO quieres que reciba un mensaje, pon tu
          email.
        </p>

        <Input
          name="giftEmail"
          placeholder="Email destinatario *"
          id="giftEmail"
          error={errors?.giftEmail}
          className="md:col-span-2"
        />

        <TextArea
          name="giftNota"
          placeholder="Mensaje *"
          id="giftNota"
          error={errors?.giftNota}
          className="md:col-span-2"
        />
        <p className="u-body u-body--s">* Campos obligatorios</p>
      </div>
    </>
  );
};
