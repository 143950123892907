import type { IconType } from './types';

export const TribuInconformistaSmile = ({
  className,
  width = 64,
  height = 64,
  color = '#DDD3C7',
}: IconType) => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 400 400"
  >
    <g>
      <circle fill={color} cx="200" cy="200" r="168.7" />
      <path d="M200,374.16c-96.03,0-174.15-78.13-174.15-174.15S103.97,25.84,200,25.84s174.16,78.13,174.16,174.16-78.13,174.15-174.16,174.15ZM200,36.75c-90.01,0-163.24,73.23-163.24,163.25s73.23,163.24,163.24,163.24,163.25-73.23,163.25-163.24S290.01,36.75,200,36.75Z" />
    </g>
    <g>
      <g>
        <g>
          <path d="M200,322.21c-67.39,0-122.21-54.82-122.21-122.21s54.82-122.21,122.21-122.21,122.21,54.82,122.21,122.21-54.82,122.21-122.21,122.21ZM200,82.45c-64.82,0-117.55,52.73-117.55,117.55s52.73,117.55,117.55,117.55,117.55-52.73,117.55-117.55-52.73-117.55-117.55-117.55Z" />
          <path d="M200,324.94c-68.89,0-124.94-56.05-124.94-124.94s56.05-124.94,124.94-124.94,124.94,56.05,124.94,124.94-56.05,124.94-124.94,124.94ZM200,85.18c-63.31,0-114.82,51.51-114.82,114.83s51.51,114.82,114.82,114.82,114.83-51.51,114.83-114.82-51.51-114.83-114.83-114.83Z" />
        </g>
        <g>
          <path d="M199.03,291.67c-32.32,0-58.61-23.05-58.61-51.39v-2.33h117.22v2.33c0,28.33-26.29,51.39-58.61,51.39ZM145.15,242.61c1.41,24.69,25.04,44.39,53.88,44.39s52.48-19.71,53.88-44.39h-107.76Z" />
          <path d="M199.03,294.39c-33.82,0-61.34-24.28-61.34-54.11v-5.06h122.67v5.06c0,29.84-27.51,54.11-61.34,54.11ZM148.15,245.34c2.94,22.05,24.66,38.94,50.88,38.94s47.95-16.88,50.89-38.94h-101.77Z" />
        </g>
      </g>
      <g>
        <path d="M263.57,203.62l-19.57-18.84-24.92,10.82,11.87-24.44-17.99-20.36,26.91,3.74,13.8-23.4,4.76,26.75,26.52,5.9-23.97,12.79,2.59,27.05ZM244.39,182.6l16.87,16.24-2.23-23.3,20.65-11.02-22.85-5.08-4.1-23.05-11.9,20.16-23.19-3.22,15.5,17.55-10.23,21.06,21.48-9.32Z" />
        <path d="M266.99,210.7l-23.57-22.69-30.01,13.02,14.29-29.44-21.65-24.51,32.4,4.5,16.63-28.18,5.73,32.21,31.94,7.1-28.86,15.41,3.11,32.57ZM244.97,179.38l12.87,12.38-1.7-17.78,15.75-8.41-17.43-3.88-3.13-17.58-9.08,15.38-17.69-2.46,11.83,13.38-7.8,16.07,16.39-7.11Z" />
      </g>
      <g>
        <path d="M181.41,196.35l-25.09-10.43-19.28,19.14,2.16-27.08-24.16-12.42,26.43-6.31,4.34-26.82,14.17,23.18,26.85-4.15-17.67,20.64,12.25,24.25ZM155.89,183.74l21.62,8.99-10.55-20.9,15.23-17.78-23.13,3.58-12.21-19.98-3.74,23.11-22.77,5.44,20.82,10.7-1.87,23.34,16.61-16.5Z" />
        <path d="M133.74,212.2l2.6-32.62-29.1-14.95,31.82-7.6,5.23-32.29,17.06,27.92,32.34-5.01-21.29,24.86,14.76,29.21-30.22-12.56-23.21,23.06ZM128.12,167.15l15.89,8.17-1.42,17.8,12.67-12.59,16.5,6.86-8.05-15.94,11.62-13.57-17.65,2.73-9.31-15.24-2.86,17.63-17.38,4.15Z" />
      </g>
    </g>
  </svg>
);
